.Parents {
    display: flex;
    flex-direction: column;

    &-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        column-gap: 32px;
        align-items: stretch;

        &__item {
            display: flex;
            flex-direction: column;
            background: #20528B;
            border-left: 2px solid #07D3C4;
            box-shadow: 0px 0px 20px rgba(16, 44, 76, 0.12);
            border-radius: 12px;
            padding: 24px;
            flex: 1;

            p {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #FEFEFE;
            }
        }

        &__mock__1 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            width: 100%;
            border-radius: 0px 12px 12px 0px;
            max-width: 401px;
            height: auto;
        }

        &__mock__2 {
            flex: 1;
            max-width: 503px;
            width: 100%;
            background: #20528B;
            opacity: 0.4;
            border-radius: 12px 0px 0px 12px;
            height: auto;
        }

        &__mock__3 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            border-radius: 0px 12px 12px 0px;
            max-width: 485px;
            width: 100%;
            height: auto;
        }

        &__mock__4 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            border-radius: 12px 0px 0px 12px;
            height: auto;
            max-width: 442px;
            width: 100%;
        }

        &__add {
            flex: 1;
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 2px solid #07D3C4;
            box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.2);
            border-radius: 12px;
            background: #20528B;
            cursor: pointer;
            &__img {
                height: 50px;
            }

            &__text {
                color: #FEFEFE;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                margin-top: 12px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .Parents {
        &-row {
            &__mock__1 {
                flex: 1 1 78px;
                max-width: 78px;
            }

            &__mock__2 {
                flex: 1 1 228px;
                max-width: 228px;
            }

            &__mock__3 {
                flex: 1 1 80px;
                max-width: 80px;
            }

            &__mock__4 {
                flex: 1 1 140px;
                max-width: 140px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .Parents {
        &-row {
            &__mock__1 {
                flex: 1 1 50px;
                max-width: 50px;
            }

            &__mock__2 {
                flex: 1 1 100px;
                max-width: 100px;
            }

            &__mock__3 {
                flex: 1 1 40px;
                max-width: 40px;
            }

            &__mock__4 {
                flex: 1 1 70px;
                max-width: 70px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .Parents {
        max-width: calc(100vw - (20px * 2));
        margin-inline: auto;

        &-row {
            flex-wrap: wrap;
            row-gap: 32px;
            .AuthorStory {
                flex-basis: calc(50% - 16px);
                box-sizing: border-box;
            }

            &__add {
                flex-basis: 100%;
            }

            &__mock__1,
            &__mock__2 ,
            &__mock__3 ,
            &__mock__4,
            &__mock__5 ,
            &__mock__6  {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .Parents {
        &-row {
            row-gap: 12px;
            margin-bottom: 12px;
            flex-direction: column;

            &:last-child {
                margin-bottom: 30px;
            }

            .AuthorStory {
                flex-basis: 100%;
                box-sizing: border-box;
            }
        }
    }
}