.StudentSignUp {
    >img {
        pointer-events: none;
    }
    // background: #102c4c;
    background: var(--accent-dark, #174273);
    // min-height: calc(100vh - 88px);
    min-height: calc(100vh - 84.8px);
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    > h3 {
        margin-top: 32px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #fefefe;
        margin-bottom: 28px;
        text-align: center;
    }

    > img {
        position: absolute;
        left: 30px;
        bottom: 0;
    }
}

@media (max-width: 309px) {
    .StudentSignUp {
        min-height: calc(100vh - 110px);
    }
}