.Faq {
    $blue: #174273;
    background: $blue;
    min-height: calc(100vh - 442px);
    &-navigation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 32px;

        >h2 {
            color: #FEFEFE;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
        }
        &__tabs {
            margin-top: 20px;
            display: flex;
            margin-bottom: 24px;
            column-gap: 12px;

            &__item {
                color: rgba(254, 254, 254, 0.6);
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                padding: 6px 12px;
                border-radius: 6px;
                border: 1px solid #20528B
            }
        }
    }
}

.isActiveFaq {
    color: #07D3C4;
    border: 1px solid #07D3C4;
}

@media screen and (max-width: 480px) {
    .Faq-navigation {
        max-width: calc(100vw - (20px * 2));
        margin-inline: auto;

        &__tabs {
            width: 100%;
            column-gap: 12px;

            &__item {
                text-align: center;
                flex: 1;
                padding: 6px 0;
                margin: 0;
            }
        }
    }
}