.AuthorStory {
    display: flex;
    flex-direction: column;
    background: #20528B;
    border-left: 2px solid #07D3C4;
    box-shadow: 0px 0px 20px rgba(16, 44, 76, 0.12);
    border-radius: 12px;
    padding: 24px;

    
    &__content {
        color: #FEFEFE;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        max-width: 412px;
        margin-bottom: 16px;


        &:nth-child(3) {
            max-width: 392px;
        }
        &:nth-child(4) {
            max-width: 410px;
        }
    }

    &__author {
        display: flex;
        &__img {
            height: 50px;
            margin-right: 16px;
        }
        
        &__name {
            display: flex;
            flex-direction: column;
            &__title {
                color: rgba(254, 254, 254, 0.6);
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
            &__pos {
                max-width: 336px;
                color: rgba(254, 254, 254, 0.6);
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}

.white-border {
    border-left: 2px solid #EAFAE6;
}