.Scenario-footer {
  background-color: #fefefe;
  padding: 24px 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  &__forQuestsions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px;
    gap: 16px;
    cursor: pointer;
    &__imgWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &__img {
        width: 70px;
      }
    }
    &.__disabled {
      opacity: 0.5;
    }
    &__text {
      &.__highlighted {
        color: #07d3c4;
      }
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #174273;
    }
  }
  &__forAnswer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    &__imgWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      gap: 16px;
      &__img {
        width: 70px;
      }
      &__text {
        width: 100%;
        max-width: 77px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #07d3c4;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .Scenario-footer {
    width: 100%;
    max-width: 600px;
    background-color: #fefefe;
    padding: 24px 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 380px) {
  .Scenario-footer {
    width: 100%;
   max-width: 380px;
    display: flex;
    padding: 24px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    &__forQuestsions {
      padding: 10px 12px;
      gap: 16px;
      &__imgWrapper {
        width: 30px;
        &__img {
          width: inherit;
          stroke-width: 2px;
          stroke: var(--accent-dark, #174273);
        }
      }
      &__text {
        color: var(--accent-dark, #174273);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
