.Terms {
  background: var(--accent-dark, #174273);
  color: var(--text-color-60, rgba(254, 254, 254, 0.60));

  /* Body/B2 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 66px 240px 66px;
  white-space: pre-line;
}

@media screen and (max-width: 1024px) {
  .Terms {
    padding: 66px 40px;
  }
}