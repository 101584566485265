.Recommendations-cont {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 16px;
  width: 100%;
  // max-width: 890px;
  flex-wrap: wrap;
  margin-top: 48px;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    gap: 12px;
    width: 100%;
    max-width: 286px;
    background: #20528b;
    border-radius: 8px;
    box-sizing: border-box;
    // cursor: pointer;
    &__topicCont {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      &__key {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgb(179, 179, 179);
      }
      &__value {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #fefefe;
      }
    }
    &__mentorCont {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      &__key {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(254, 254, 254, 0.4);
      }
      &__value {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #fefefe;
      }
    }
    &__lane {
      width: 100%;
      max-width: 254px;
      height: 1px;
      background: #fefefe;
      opacity: 0.2;
      border-radius: 6px;
      margin-top: auto;
    }
    &__StarandRateCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 12px;
      width: 100%;
      &__rate {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(254, 254, 254, 0.6);
      }
    }
    &__btn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 6px;
      width: 100%;
      max-width: 254px;
      height: 40px;
      border: 1px solid #07d3c4;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #07d3c4;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .Recommendations-cont {
    display: flex;
    justify-content: center;
    // flex-direction: column;
    padding: 0px;
    gap: 16px;
    margin-top: 0px;
    width: 100%;
    max-width: 1024px;
    &__item {
      width: 100%;
      max-width: 320px;
      &__lane {
        width: 100%;
        max-width: 288px;
      }
      &__btn {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .Recommendations-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin-top: 0px;
    &__item {
      width: 100%;
      max-width: 320px;
      &__lane {
        width: 100%;
        max-width: 288px;
      }
      &__btn {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}
