.Students {
    display: flex;
    flex-direction: column;

    &-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        column-gap: 32px;

        &__mock__1 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            width: 100%;
            border-radius: 0px 12px 12px 0px;
            max-width: 401px;
            height: 186px;
        }

        &__mock__2 {
            flex: 1;
            max-width: 503px;
            width: 100%;
            height: 186px;
            background: #20528B;
            opacity: 0.4;   
            border-radius: 12px 0px 0px 12px;
        }

        &__mock__3 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            border-radius: 0px 12px 12px 0px;
            max-width: 485px;
            width: 100%;
            height: 161px;
        }

        &__mock__4 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            border-radius: 12px 0px 0px 12px;
            height: 161px;
            max-width: 442px;
            width: 100%;
        }
        &__mock__5 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            border-radius: 0px 12px 12px 0px;
            max-width: 359px;
            height: 139px;
        }
        &__mock__6 {
            flex: 1;
            background: #20528B;
            opacity: 0.4;
            border-radius: 12px 0px 0px 12px;
            max-width: 370px;
            width: 100%;
            height: 139px;
        }

        &__add {
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 2px solid #07D3C4;
            box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.2);
            border-radius: 12px;
            background: #20528B;
            cursor: pointer;
            &__img {
                height: 50px;
            }

            &__text {
                color: #FEFEFE;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                margin-top: 12px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .Students {
        &-row {
            align-items: stretch;
            &__mock__1 {
                height: auto;
                flex: 1 1 200px;
                max-width: 200px;
            }

            &__mock__2 {
                height: auto;
                flex: 1 1 64px;
                max-width: 64px;
            }

            &__mock__3 {
                height: auto;
                flex: 1 1 80px;
                max-width: 80px;
            }

            &__mock__4 {
                height: auto;
                flex: 1 1 161px;
                max-width: 161px;
            }

            &__mock__5 {
                height: auto;
                flex: 1 1 52px;
                max-width: 52px;
            }

            &__mock__6 {
                height: auto;
                flex: 1 1 32px;
                max-width: 32px;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .Students {
        max-width: calc(100vw - (20px * 2));
        margin-inline: auto;

        &-row {
            flex-wrap: wrap;
            row-gap: 32px;
            .AuthorStory {
                flex-basis: calc(50% - 16px);
                box-sizing: border-box;
            }

            &__add {
                flex-basis: 100%;

            }

            &__mock__1,
            &__mock__2 ,
            &__mock__3 ,
            &__mock__4,
            &__mock__5 ,
            &__mock__6  {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .Students {
        &-row {
            row-gap: 12px;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 30px;
            }

            .AuthorStory {
                flex-basis: 100%;
                box-sizing: border-box;
            }
        }
    }
}