$blue: #174273;

.Navigation {
  overflow-x: hidden;
  background: $blue;
  border-bottom: 1px solid rgba(254, 254, 254, 0.4);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-content {
    display: flex;
    &__logo {
      width: 84px;
      height: 44px;
    }

    &__items {
      display: flex;
      margin-left: 60px;
      align-items: center;
      &__item {
        color: #fefefe;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 54px;
      }
    }
  }

  .menu__box {
    display: none;
  }

  &-buttons {
    display: flex;
    &__login {
      color: #fefefe;
      background: none;
      display: flex;
      align-items: center;
      border: none;
      margin-right: 32px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    &__signup {
      color: #fefefe;
      padding: 8px 24px;
      background: $blue;
      border-bottom: 1px solid rgba(254, 254, 254, 0.4);
      border: 1px solid #fefefe;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  &-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    > img {
      width: 44px;
      height: 44px;
      margin-right: 8px;
      border-radius: 50%;
      object-fit: cover;
    }
    &__info {
      display: flex;
      flex-direction: column;
      > p {
        color: #fefefe;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      > h3 {
        background: linear-gradient(90deg, #07d3c4 0%, #0040ff 100%);
        border-radius: 2px;
        padding: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $blue;
      }
    }
  }
}

@media (max-width: 270px) {
  .menu__box-bottom {
    flex-direction: column;
    row-gap: 20px;

    > a {
      width: 100%;
    }
  }
}

@media screen and (min-width: 220px) {
  .Navigation {
    // padding: 10px 20px;
    &-content {
      &__items {
        display: none;
      }
    }
    &-buttons {
      display: none;
    }

    .menu__box {
      height: calc(100vh - 80px);
      overflow: scroll;
      background: $blue;
      position: fixed;
    //   top: 63px;
      top: 80px;
    //   width: calc(100vw - 40px);
      width: calc(100vw);
      padding: 0 20px;
      z-index: 100;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transform: translateX(100%);
      transition: transform 0.2s ease;

      &-bottom {
        padding: 24px 20px 80px;
        border-top: 1px solid #20528b;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;

        .menu__box-btn {
          text-align: center;
          flex: 1;
          padding: 8px;
          border-radius: 8px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          &:nth-child(1) {
            color: #fefefe;
            background: transparent;
            border: 1px solid #fefefe;
          }

          &:nth-child(2) {
            color: #fefefe;
            background: #07d3c4;
            border: 1px solid $blue;
          }
        }
      }

      &-top {
        border-top: 1px solid #20528b;
        // padding: 127px 20px 0;
        height: 100%;
        margin: 24px 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 44px;

        .menu__box-link {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #fefefe;
        }
      }
    }

    #menu__toggle:checked + body {
      overflow: hidden;
    }

    #menu__toggle:checked ~ .Navigation-menu > span {
      opacity: 0;
    }
    #menu__toggle:checked ~ .Navigation-menu::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    #menu__toggle:checked ~ .Navigation-menu::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    #menu__toggle:checked ~ .menu__box {
      transform: translateX(0);
    }

    #menu__toggle:checked
      ~ .Navigation-content
      .Navigation-content__logo::before {
      content: "Menu";
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #fefefe;
    }

    #menu__toggle:checked ~ .Navigation-content .Navigation-content__logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #menu__toggle:checked
      ~ .Navigation-content
      .Navigation-content__logo
      > img {
      display: none;
    }

    #menu__toggle:checked ~ .Navigation-menu {
      height: 18px;
      width: 18px;
    }

    &-menu {
      display: block;
      width: 22px;
      height: 18px;
      position: relative;
      background: transparent;
      border: none;

      &::after,
      &::before,
      > span {
        transition: 0.2s ease;
        display: block;
        height: 2px;
        width: 100%;
        background: #fefefe;
        content: "";
        border-radius: 6px;
        position: absolute;
        left: 0;
        z-index: 1;
      }

      > span {
        top: 50%;
        transform: translateY(-50%);
      }

      &:after {
        top: 0;
      }

      &:before {
        bottom: 0;
      }
    }
  }
  .profile-Navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
    gap: 8px;
    width: 100%;
    border-bottom: 1px solid rgba(254, 254, 254, 0.4);
    background-color: $blue;
    box-sizing: border-box;
    &__iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      &__img {
        width: 14px;
        height: 7px;
        transform: rotate(90deg);
      }
    }
    &__pageName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fefefe;
    }
  }
}

@media screen and (min-width: 768px) {
  .Navigation {
    // padding: 20px 30px;
    &-content {
      &__items {
        display: flex;
      }

      &__logo {
        img {
          height: 44px;
          width: 84px;
        }
      }
    }
    &-buttons {
      display: flex;
    }

    &-menu {
      display: none;
    }

    .menu__box {
      height: calc(100vh - 85px);
      width: calc(100vw);
      top: 85px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .Navigation {
    padding: 20px 240px;
  }
}