.ForgotPassword {
  background: #174273;
  min-height: calc(100vh - 84px);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  color: #fefefe;
  justify-content: center;

  &-cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    gap: 24px;
    width: 100%;
    max-width: 360px;

    &__title {
      color: #fefefe;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
    &__input {
      width: 100%;
      max-width: 360px;

      .input {
        max-width: 360px;
        width: 100%;
        > p {
          color: var(--text-color-60, rgba(254, 254, 254, 0.6));
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 153.846% */
          margin-bottom: 8px;
        }
        > input {
          width: 100%;
          max-width: 360px;
          background: var(--accent-main, #20528b);
          border-radius: 8px;
          font-size: 14px;
          line-height: 22px;
          padding: 9px 16px;
          border: none;
          color: #fefefe;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          outline: none;
          &::placeholder {
            color: var(--text-color-60, rgba(254, 254, 254, 0.6));
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 153.846% */
          }
        }
      }
    }
    &__navigation {
      display: flex;
      gap: 24px;
      width: 100%;
      max-width: 360px;

      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        height: 40px;
      }

      &__back {
        border: 1px solid #07d3c4;
        border-radius: 8px;
        background: transparent;
        color: #07d3c4;
        &__backText {
          color: var(--accent-brand, #07d3c4);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
      &__email {
        background: #07d3c4;
        border-radius: 8px;
        border: none;
        &__emailText {
          color: var(--accent-dark, #174273);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
    }
  }

  > img {
    position: absolute;
    right: 0;
  }

  .errorCont {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    &__text {
      color: var(--fixed-color-red, #ff002f);
      font-size: 13px;
      line-height: 20px;
      display: flex;
      margin-left: 8px;
    }
  }

  &__PasswordPage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    gap: 24px;
    width: 100%;
    max-width: 360px;
    &__title {
      color: var(--text-color-main, #fefefe);
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    &__cont {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
      max-width: 360px;
      &__password {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        &__text {
          color: var(--text-color-60, rgba(254, 254, 254, 0.6));
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        &__inpCont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 8px;
          background: var(--accent-main, #20528b);
          width: 100%;
          box-sizing: border-box;
          padding: 9px 16px;
          &__inp {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 8px;
            background: var(--accent-main, #20528b);
            outline: none;
            border: none;
            color: var(--text-color-main, #fefefe);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
          &__img {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ForgotPassword {
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    &-cont {
      width: 100%;
      max-width: 320px;
      justify-content: flex-start;
      margin-top: 16px;
      height: calc(100vh - (16px + 84.8px));
      padding-bottom: 20px;
      gap: 32px;
      &__input {
        width: 100%;
        max-width: 320px;
        .input {
          width: 100%;
          max-width: 320px;
          input {
            width: 100%;
          }
        }
      }
      &__navigation {
        width: 100%;
        max-width: 320px;
        margin-top: auto;
      }
    }
    > img {
      display: none;
    }
    &__PasswordPage {
      justify-content: flex-start;
      box-sizing: border-box;
      min-height: calc(100vh - (16px + 84.8px));
      gap: 32px;
      &__title {
        margin-top: 16px;
      }
      &__cont {
        max-width: 320px;
      }
    }
  }
}
