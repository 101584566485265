.Scenario1 {
    background: #174273;
    min-height: calc(100vh - 85px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    &-cont {
        padding: 64px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        >h1 {
            color: var(--text-color-main, #FEFEFE);
            text-align: center;
            font-family: Poppins;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px; /* 150% */
        }

        >div {
            width: 640px;
            height: 320px;
            display: flex;
            margin-top: 20px;
            justify-content: center;
            align-items: center;

            video {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1919px) {
    .Scenario1 {
        &-cont {
            >div {
                max-width: 640px;
                width: calc(100vw - 40px);
            }
        }
    }
}