.MyFeedBack-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  &__btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 11px;
    gap: 10px;
    width: 100%;
    max-width: 130px;
    height: 32px;
    border: 1px solid #20528b;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    &__text {
      width: 100%;
      max-width: 106px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: rgba(254, 254, 254, 0.6);
    }
  }
  .activeBtn {
    border: 1px solid #07d3c4;
    border-radius: 6px;
  }
  .activeBtnText {
    color: #07d3c4;
  }
}

@media screen and (max-width: 1024px) {
  .MyFeedBack-cont {
    width: 100%;
    max-width: 320px;
    &__btn {
      width: 100%;
      max-width: 154px;
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 360px) {
  .MyFeedBack-cont {
    width: 100%;
    max-width: 320px;
    &__btn {
      width: 100%;
      max-width: 154px;
      align-items: center;
      justify-content: center;
    }
  }
}
