.Scenario2Intro-cont {
  height: calc(100vh - (92.8px + 92px));
  background-color: #174273;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &__textCont {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    &__title {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 400;
      line-height: 60px;
      letter-spacing: 0em;
      text-align: center;
      width: 100%;
      color: #fefefe;
    }
    &__qestion {
      font-size: 18px;
      font-family: Poppins;
      font-weight: 600;
      line-height: 24px;
      width: 100%;
      max-width: 620px;
      color: #ffbe00;
      text-align: center;
    }
  }
  &__imgsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-end;

  }
}
