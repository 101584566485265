.Feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  padding-top: 60px;
  > p {
    color: #102c4c;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 32px;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 32px;
    max-width: calc(100vw - (350px * 2));

    .item {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 16px;
      background: #fefefe;
      box-shadow: 0px 0px 20px rgba(16, 44, 76, 0.12);
      border-radius: 8px;
      &:not(.active) {
        flex: 1;
      }

      &-author {
        width: 100%;
        max-width: 108px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        &__image-container {
          width: 78px;
          height: 78px;
          overflow: hidden;

          > img {
            width: 78px;
          }
        }
        p {
          margin-top: 16px;
          color: #174273;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      &-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        &__title {
          color: #174273;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > p {
            font: inherit;
          }
        }

        &__text {
          color: #20528b;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px;
          max-width: 575px;
        }
      }
    }
  }
}

@media screen and (max-width: 1919px) {
  .Feedback {
    &-container {
      max-width: calc(100vw - (250px * 2));
    }
  }
}

@media screen and (max-width: 1281px) {
  .Feedback {
    &-container {
      max-width: calc(100vw - (60px * 2));
    }
  }
}

@media screen and (max-width: 991px) {
  .Feedback-container {
    column-gap: 16px;
  }
}

@media screen and (max-width: 768px) {
  .Feedback {
    margin-top: 32px;
    padding-top: 0px;

    > p {
      max-width: calc(100vw - (20px * 2));
      text-align: center;
      margin-inline: auto;
    }

    &-container {
      max-width: calc(100vw - (20px * 2));
      flex-direction: column;
      column-gap: 0;
      row-gap: 20px;

      .item {
        flex-direction: column;
        row-gap: 24px;
        position: relative;

        &-author {
          align-items: center;
        }

        &-content__title > p,
        &-content__text {
          text-align: center;
        }

        img[alt~="Apostrophe"] {
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
    }
  }
}
