.Scenario4 {
  background: #174273;
  min-height: calc(100vh - 92.8px);
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &__question13 {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;
    &__title {
      color: var(--text-color-main, #fefefe);
      text-align: center;
      font-size: 40px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      line-height: 60px;
    }
    &__introText {
      color: var(--text-color-60, rgba(254, 254, 254, 0.6));
      text-align: center;
      font-size: 16px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &__cupImgs {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      &__imgWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        position: relative;
        cursor: pointer;
        &__img {
          border: 1px solid transparent;
          width: 120px;
          height: 120px;
        }
        &__imgText {
          color: var(--text-color-main, #fefefe);
          font-size: 16px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
    &__emptyBoards {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      margin-top: 12px;
      width: 100%;
      &__boardWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        &__board {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 122px;
          height: 122px;
          border-radius: 8px;
          border: 1px dashed var(--text-color-60, rgba(254, 254, 254, 0.6));
          &__text {
            color: var(--text-color-main, #fefefe);
            font-size: 16px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }
  }
  .dropped {
    opacity: 0.3;
    cursor: no-drop;
  }
  .selected {
    border: 1px solid var(--accent-brand, #07d3c4);
    border-radius: 8px;
  }
}
@media screen and (max-width: 1024px) {
  .Scenario4 {
    width: 100%;
    padding-top: 0px;
    &__question13 {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 32px;
      margin-bottom: 26px;
      box-sizing: border-box;
      &__title {
        display: none;
      }
      &__introText {
        display: none;
      }
      &__cupImgs {
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
        &__imgWrapper {
          width: 100%;
          max-width: 90px;
          &__img {
            width: 90px;
            height: 100px;
          }
          &__imgText {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
      &__emptyBoards {
        margin-top: 0px;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;
        &__boardWrapper {
          width: 100%;
          max-width: 90px;
          gap: 8px;
          &__board {
            width: 96px;
            height: 96px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px dashed var(--text-color-60, rgba(254, 254, 254, 0.6));
            > img {
              width: 92px;
              height: 92px;
            }
            &__text {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .Scenario4 {
    width: 100%;
    max-width: 360px;
    padding-top: 0px;
    &__question13 {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 32px;
      margin-bottom: 26px;
      box-sizing: border-box;
      &__title {
        display: none;
      }
      &__introText {
        display: none;
      }
      &__cupImgs {
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
        &__imgWrapper {
          width: 100%;
          max-width: 90px;
          &__img {
            width: 90px;
            height: 100px;
          }
          &__imgText {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
      &__emptyBoards {
        margin-top: 0px;
        width: 100%;
        flex-wrap: wrap;
        gap: 10px;
        &__boardWrapper {
          width: 100%;
          max-width: 90px;
          gap: 8px;
          &__board {
            width: inherit;
            height: 92px;
            > img {
              width: inherit;
              height: 92px;
            }
            &__text {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
