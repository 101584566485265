// Import css files
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
ul{
    margin-top: 0;
    margin-bottom: 0;
}

h1,h2,h3,p {
    margin-top: 0;
    margin-bottom: 0;
}

a{
    text-decoration: none;
}

img {
    display: block
}

.displayNone{
    display: none;
}

*,
*::after,
*::before {
    font-family: "Poppins", sans-serif;
}
  
.visually-hidden {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.App {
    overflow-y: hidden;
}
