* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.test {
	position: absolute;
	color: red;
}

.SecondForm1 {
	max-width: 444px;
	padding: 0 10px;

	&-cont {
		display: flex;
		gap: 24px;

		> div {
			flex: 0 1 200px;
		}
		.input {
			margin-top: 16px;
			&:nth-child(1) {
				margin-top: 0;
			}
			> p {
				font-size: 13px;
				line-height: 20px;
				color: rgba(254, 254, 254, 0.6);
				margin-bottom: 8px;
			}

			.passwordInput {
				position: relative;
			}

			input {
				// background: #254365;
				background: var(--accent-main, #20528b);
				border-radius: 8px;
				font-size: 14px;
				line-height: 22px;
				padding: 9px 16px;
				border: none;
				color: #fefefe;
				width: 100%;
				outline: none;
			}

			&-password {
				position: relative;
			}

			&-passimg,
			&-confpassimg {
				cursor: pointer;
				position: absolute;
				// top: 38px;
				top: calc(10px);
				right: 16px;
			}
		}
	}

	&__tag {
		margin-top: 16px;
		> p {
			color: rgba(254, 254, 254, 0.6);
			font-size: 13px;
			line-height: 20px;
			margin-bottom: 8px;
		}

		> input {
			// width: calc(100% - 24px);
			// max-width: calc(100% - 24px);
			width: 100%;
			outline: none;
			// background: #254365;
			background: var(--accent-main, #20528b);
			border-radius: 8px;
			border: none;
			padding: 9px 16px;
			color: #fefefe;
		}

		&__container {
			display: flex;
			max-width: 400px;
			flex-wrap: wrap;

			> div {
				padding: 6px 12px;
				display: flex;
				border: 1px solid #254365;
				border-radius: 6px;
				justify-content: space-between;
				max-width: 140px;
				margin-top: 16px;
				cursor: pointer;

				> p {
					font-weight: 500;
					font-size: 13px;
					margin-right: 14px;
					line-height: 20px;
					color: rgba(254, 254, 254, 0.6);
				}
			}
		}

		&__info {
			display: flex;
			margin-top: 8px;
			> img {
				margin-right: 8px;
			}
			> p {
				color: rgba(254, 254, 254, 0.6);
				font-size: 13px;
				line-height: 20px;
			}
		}
	}
	&__linkedin {
		margin-top: 16px;
		> p {
			color: rgba(254, 254, 254, 0.6);
			font-size: 13px;
			line-height: 20px;
			margin-bottom: 8px;
		}
		> input {
			// background: #254365;
			background: var(--accent-main, #20528b);
			border-radius: 8px;
			font-size: 14px;
			line-height: 22px;
			padding: 9px 16px;
			width: calc(100% - 24px);
			border: none;
			color: #fefefe;
		}
	}

	&-agreement {
		ul {
			margin: 16px 0 24px 0;
			padding-left: 0 !important;

			li:not(:first-child) {
				margin-top: 16px;
			}

			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				list-style-type: none;

				input {
					margin: 0 12px 0 0;
					width: 16px;
					height: 16px;
					position: relative;
				}

				input[type="checkbox"]::before {
					position: absolute;
					pointer-events: none;
					// background: red;
					background: var(--accent-main, #20528b);
					width: 14px;
					height: 14px;
					content: " ";
					border: 1px solid var(--text-color-60, rgba(254, 254, 254, 0.6));
				}

				input[type="checkbox"]:checked::after {
					position: absolute;
					text-align: center;
					margin-top: -2px;
					top: 1px;
					left: 1px;
					height: 14px;
					width: 14px;
					font-size: 13px;
					content: "✓";
					color: white;
				}

				p {
					color: var(--text-color-60, rgba(254, 254, 254, 0.6));
					font-size: 12px;
					line-height: 18px;

					a {
						color: var(--text-color-60, rgba(254, 254, 254, 0.6));
						text-decoration: underline;
						text-decoration-color: var(
							--text-color-60,
							rgba(254, 254, 254, 0.6)
						);
					}
				}
			}
		}
	}

	&-navigation {
		display: flex;
		margin-top: 16px;
		gap: 24px;
		margin-bottom: 20px;

		> button {
			width: 100%;
			padding: 8px 0;
			cursor: pointer;
		}

		&__back {
			border: 1px solid #07d3c4;
			border-radius: 8px;
			background: transparent;
			color: #07d3c4;
		}
		&__continue {
			background: #07d3c4;
			border-radius: 8px;
			color: #102c4c;
			border: none;
		}
	}
	.mentorSInupsecondPage-lastStep {
		margin-left: 12px;
	}
}
