.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 150px;
}

.loader {
  display: block;
  position: relative;
  height: 20px;
  width: 86px;
}

.loading-square {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
}

.loading-square:nth-child(1) {
  left: 0;
  animation: square1 1.5s linear forwards infinite;
}

.loading-square:nth-child(2) {
  left: 22px;
  animation: square2 1.5s linear forwards infinite;
}

.loading-square:nth-child(3) {
  left: 44px;
  animation: square3 1.5s linear forwards infinite;
}

.loading-square:nth-child(4) {
  left: 66px;
  animation: square4 1.5s linear forwards infinite;
}

@keyframes square1 {
  0% {
    background-color: #97c900;
    transform: translate(0, 0);
  }

  9.09091% {
    transform: translate(0, calc(-100% - 2px));
    background-color: #97c900;
  }

  18.18182% {
    transform: translate(calc(100% + 2px), calc(-100% - 2px));
    background-color: #15668a;
  }

  27.27273% {
    transform: translate(calc(100% + 2px), 0);
  }

  100% {
    background-color: #15668a;
    transform: translate(calc(100% + 2px), 0);
  }
}

@keyframes square2 {
  0% {
    background-color: #15668a;
    transform: translate(0, 0);
  }

  18.18182% {
    transform: translate(0, 0);
  }

  27.27273% {
    transform: translate(0, calc(100% + 2px));
    background-color: #15668a;
  }

  36.36364% {
    transform: translate(calc(100% + 2px), calc(100% + 2px));
    background-color: #d53a33;
  }

  45.45455% {
    transform: translate(calc(100% + 2px), 0);
  }

  100% {
    background-color: #d53a33;
    transform: translate(calc(100% + 2px), 0);
  }
}

@keyframes square3 {
  0% {
    background-color: #d53a33;
    transform: translate(0, 0);
  }

  36.36364% {
    transform: translate(0, 0);
  }

  45.45455% {
    transform: translate(0, calc(-100% - 2px));
    background-color: #d53a33;
  }

  54.54545% {
    transform: translate(calc(100% + 2px), calc(-100% - 2px));
    background-color: #e79c10;
  }

  63.63636% {
    transform: translate(calc(100% + 2px), 0);
  }

  100% {
    background-color: #e79c10;
    transform: translate(calc(100% + 2px), 0);
  }
}

@keyframes square4 {
  0% {
    transform: translate(0, 0);
    background-color: #e79c10;
  }

  54.54545% {
    transform: translate(0, 0);
  }

  63.63636% {
    transform: translate(0, calc(100% + 2px));
    background-color: #e79c10;
  }

  72.72727% {
    background-color: #d53a33;
  }

  81.81818% {
    background-color: #15668a;
  }

  90.90909% {
    transform: translate(calc(-300% - 6px), calc(100% + 2px));
    background-color: #97c900;
  }

  100% {
    transform: translate(calc(-300% - 6px), 0);
    background-color: #97c900;
  }
}



.dot-spinner {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    animation: dot-rotate 1.5s linear infinite;

    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: 15%;
      left: 85%;
      transform: translate(-50%, -50%);
      animation-delay: 0.1875s;
    }

    &:nth-child(3) {
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      animation-delay: 0.375s;
    }

    &:nth-child(4) {
      top: 85%;
      left: 85%;
      transform: translate(-50%, -50%);
      animation-delay: 0.5625s;
    }

    &:nth-child(5) {
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation-delay: 0.75s;
    }

    &:nth-child(6) {
      top: 85%;
      left: 15%;
      transform: translate(-50%, -50%);
      animation-delay: 0.9375s;
    }

    &:nth-child(7) {
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      animation-delay: 1.125s;
    }

    &:nth-child(8) {
      top: 15%;
      left: 15%;
      transform: translate(-50%, -50%);
      animation-delay: 1.3125s;
    }
  }
}
@keyframes dot-rotate {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.5;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}