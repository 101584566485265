.UpdateImageCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - (56.8px + 16px));
  padding-bottom: 20px;
  &__imgWrapper {
    width: 160px;
    height: 160px;
    border-radius: 160px;
    border: 1px solid var(--accent-brand, #07d3c4);
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: auto;
    &__human {
      width: 40px;
      height: 40px;
    }
    &__addImage {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 5px;
      top: 128px;
      background: #07d3c4;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  &__btnCont {
    margin-top: auto;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    &__cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex: 1 0 0;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid var(--accent-main, #20528b);
      height: 40px;
      &__text {
        color: var(--text-color-60, rgba(254, 254, 254, 0.6));
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
    &__save {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex: 1 0 0;
      border-radius: 8px;
      background: var(--accent-brand, #07d3c4);
      cursor: pointer;
      border: none;
      height: 40px;
      &__text {
        color: var(--accent-dark, #174273);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
  }
   .mainImg{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
