.StatusBar {
    max-width: 500px;
    display: flex;
    margin-top: 16px;

    > div {
        margin-left: 4px;
        border-radius: 6px;
        width: 50px;
        height: 3px;
    }
    .active {
        background: #07D3C4;
    }
    .disactive {
        background: #20528B;
    }
}

@media (max-width: 360px) {
    .StatusBar {
        > div {
            width: 36px
        }
    }
}