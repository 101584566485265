.Scenario3 {
  background: #174273;
  min-height: calc(100vh - 85px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  &__BiscuitPart {
    display: flex;
    gap: 40px;
    padding-top: 60px;
    box-sizing: border-box;
    margin-bottom:auto;
    align-items: center;
    margin-left: -120px;
    &__Imgwrapper {
      width: 280px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__biscuit {
        width: 280px;
        height: 280px;
      }
    }
    &__scenario {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 60px;
      width: 100%;
      max-width: 800px;
      &__textWrapper {
        display: flex;
        justify-content: center;
        &__img {
          width: 100px;
          height: 100px;
        }
        &__title {
          color: var(--text-color-main, #fefefe);
          text-align: center;
          font-size: 40px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          line-height: 60px;
        }
      }
      &__advises {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        &__text {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          color: var(--text-color-60, rgba(254, 254, 254, 0.6));
          text-align: center;
          width: 100%;
          font-size: 16px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &__ratingRange-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 44px;
        width: 100%;
        max-width: 700px;
        &__text {
          color: var(--fixed-color-yellow, #ffbe00);
          text-align: center;
          font-size: 18px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        &__sliderCont {
          width: 100%;
          max-width: 700px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 40px;
          &__textPart {
            display: flex;
            width: 100%;
            max-width: 320px;
            justify-content: space-between;
          }
          &__leftText {
            color: var(--text-color-40, rgba(254, 254, 254, 0.4));
            text-align: right;
            font-size: 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            max-width: 140px;
            width: 100%;
          }
          &__rateCont {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            max-width: 360px;
            width: 100%;
            &__circles {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 2px 5px;
              gap: 2px;
              isolation: isolate;
              max-width: 360px;
              width: 100%;
              height: 11px;
              background: #20528b;
              box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
              border-radius: 20px;
              position: relative;
              &__circle {
                width: 7px;
                height: 7px;
                background: rgba(254, 254, 254, 0.4);
                border-radius: 3.5px;
                transform: matrix(-1, 0, 0, 1, 0, 0);
              }
              &__inp {
                margin: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                gap: 2px;
                isolation: isolate;
                width: 100%;
                height: 11px;
                background: transparent;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
                border-radius: 20px;
                position: absolute;
                accent-color: #20528b;
                outline: none;
                border: none;
                appearance: none;
              }
            }
            &__num-cont {
              max-width: 360px;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 2px 5px;
              gap: 2px;
              isolation: isolate;
              &__num {
                width: 8px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #07d3c4;
              }
            }
          }
          &__rightText {
            color: var(--text-color-40, rgba(254, 254, 254, 0.4));
            font-size: 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            width: 100%;
            max-width: 140px;
            text-align: left;
          }
        }
      }
    }
  }
  #Sceanrio-3Slider::-webkit-slider-thumb {
    appearance: none;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    background: #20528b;
    border: 2px solid #07d3c4;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    flex: none;
    order: 9;
    flex-grow: 0;
    z-index: 9;
    border-radius: 50%;
    cursor: pointer;
  }
  .slider_none::-webkit-slider-thumb {
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
  #Sceanrio-3Slider.cneter-2::-webkit-slider-thumb {
    transform: translate(-5px);
  }
  #Sceanrio-3Slider.cneter-1::-webkit-slider-thumb {
    transform: translate(-2.5px);
  }
  #Sceanrio-3Slider.end-2::-webkit-slider-thumb {
    transform: translate(4px);
  }
  #Sceanrio-3Slider.end-1::-webkit-slider-thumb {
    transform: translate(5px);
  }
  .none {
    display: none;
  }
}
@media screen and (max-width: 1900px) {
  .Scenario3 {
    background: #174273;
    min-height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__BiscuitPart {
      display: flex;
      gap: 40px;
      padding-top: 60px;
      box-sizing: border-box;
      margin-bottom: auto;
      align-items: center;
      margin-left: -240px;
    }
  }
}
@media screen and (max-width: 1600px) {
  .Scenario3 {
    background: #174273;
    min-height: calc(100vh - 85px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__BiscuitPart {
      display: flex;
      gap: 40px;
      padding-top: 60px;
      box-sizing: border-box;
      margin-bottom: auto;
      align-items: center;
      margin-left: -100px;
    }
  }
}
@media screen and (max-width: 1300px) {
  .Scenario3 {
    background: #174273;
    min-height: calc(100vh - 85px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    &__BiscuitPart {
      display: flex;
      gap: 10px;
      padding-left: 40px;
      padding-top: 20px;
      box-sizing: border-box;
      align-items: flex-start;
      margin-left: 0px;
      margin-bottom: auto;
      &__Imgwrapper {
        width: 280px;
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__biscuit {
          width: inherit;
          height: inherit;
        }
      }
      &__scenario {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 100%;
        max-width: 700px;
        margin-top: 20px;
        &__textWrapper {
          display: flex;
          justify-content: center;
          &__img {
            width: 100px;
            height: 100px;
          }
          &__title {
            color: var(--text-color-main, #fefefe);
            text-align: center;
            font-size: 40px;
            font-family: Staatliches;
            font-style: normal;
            font-weight: 400;
            line-height: 60px;
          }
        }
        &__advises {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          &__text {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            color: var(--text-color-60, rgba(254, 254, 254, 0.6));
            text-align: center;
            width: 100%;
            max-width: 620px;
            font-size: 16px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }
        &__ratingRange-part {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 44px;
          &__text {
            color: var(--fixed-color-yellow, #ffbe00);
            text-align: center;
            margin-top: 26px;
            font-size: 18px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          &__sliderCont {
            width: 100%;
            max-width: 672px;
            display: flex;
            justify-content: space-between;
            &__textPart {
              display: flex;
              width: 100%;
              max-width: 320px;
              justify-content: space-between;
            }
            &__leftText {
              color: var(--text-color-40, rgba(254, 254, 254, 0.4));
              text-align: right;
              /* Body/B3 */
              font-size: 14px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              max-width: 140px;
              width: 100%;
            }
            &__rateCont {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 6px;
              max-width: 360px;
              width: 100%;
              &__circles {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 2px 5px;
                gap: 2px;
                isolation: isolate;
                max-width: 360px;
                width: 100%;
                height: 11px;
                background: #20528b;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
                border-radius: 20px;
                position: relative;
                &__circle {
                  width: 7px;
                  height: 7px;
                  background: rgba(254, 254, 254, 0.4);
                  border-radius: 3.5px;
                  transform: matrix(-1, 0, 0, 1, 0, 0);
                }
                &__inp {
                  margin: 0;
                  left: 0;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  cursor: pointer;
                  gap: 2px;
                  isolation: isolate;
                  width: 100%;
                  height: 11px;
                  background: transparent;
                  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
                  border-radius: 20px;
                  position: absolute;
                  accent-color: #20528b;
                  outline: none;
                  border: none;
                  appearance: none;
                }
              }
              &__num-cont {
                max-width: 360px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 2px 5px;
                gap: 2px;
                isolation: isolate;
                &__num {
                  width: 8px;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 22px;
                  text-align: center;
                  color: #07d3c4;
                }
              }
            }
            &__rightText {
              color: var(--text-color-40, rgba(254, 254, 254, 0.4));
              font-size: 14px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
          }
        }
      }
    }
    #Sceanrio-3Slider::-webkit-slider-thumb {
      appearance: none;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      background: #20528b;
      border: 2px solid #07d3c4;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      flex: none;
      order: 9;
      flex-grow: 0;
      z-index: 9;
      border-radius: 50%;
      cursor: pointer;
    }
    .slider_none::-webkit-slider-thumb {
      visibility: hidden;
      z-index: -1;
      position: absolute;
    }
    #Sceanrio-3Slider.cneter-2::-webkit-slider-thumb {
      transform: translate(-5px);
    }
    #Sceanrio-3Slider.cneter-1::-webkit-slider-thumb {
      transform: translate(-2.5px);
    }
    #Sceanrio-3Slider.end-2::-webkit-slider-thumb {
      transform: translate(4px);
    }
    #Sceanrio-3Slider.end-1::-webkit-slider-thumb {
      transform: translate(5px);
    }
    .none {
      display: none;
    }
  }
}
@media screen and (max-width: 1024px) {
  .Scenario3 {
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    &__BiscuitPart {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      margin-bottom: auto;
      gap: 0px;
      &__scenario {
        width: 100%;
        max-width: 500px;
        gap: 16px;
        &__textWrapper {
          gap: 12px;
          width: 100%;
          justify-content: flex-start;
          &__title {
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            display: flex;
            align-items: center;
          }
        }
        &__advises {
          width: 100%;
          &__text {
            font-size: 14px;
            line-height: 22px;
            width: 100%;
            max-width: 320px;
          }
        }
        &__ratingRange-part {
          width: 100%;
          max-width: 320px;
          gap: 16px;
          &__text {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            margin-top: 0px;
          }
          &__sliderCont {
            width: 100%;
            max-width: 360px;
            flex-direction: column;
            gap: 24px;
            &__leftText {
              width: 100%;
              max-width: 140px;
              text-align: left;
            }
            &__rightText {
              width: 100%;
              max-width: 140px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .Scenario3 {
    width: 100%;
    &__BiscuitPart {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      margin-bottom: auto;
      gap: 0px;
      &__scenario {
        width: 100%;
        max-width: 320px;
        gap: 16px;
        &__textWrapper {
          gap: 12px;
          width: 100%;
          justify-content: flex-start;
          &__title {
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            display: flex;
            align-items: center;
          }
        }
        &__advises {
          width: 100%;
          &__text {
            font-size: 14px;
            line-height: 22px;
            width: 100%;
            max-width: 320px;
          }
        }
        &__ratingRange-part {
          width: 100%;
          max-width: 320px;
          gap: 16px;
          &__text {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            margin-top: 0px;
          }
          &__sliderCont {
            width: 100%;
            max-width: 360px;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 40px;
            &__leftText {
              width: 100%;
              max-width: 140px;
              text-align: left;
            }
            &__rightText {
              width: 100%;
              max-width: 140px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
