.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  padding: 20px;
  border-radius: 4px;
  width: 400px;
  max-width: 100%;
  text-align: center;
  position: relative;
  border: 1px solid var(--accent-brand, #07d3c4);
  background: var(--accent-dark, #174273);
  box-shadow: 0px 0px 20px 0px rgba(7, 211, 196, 0.5);
}

.close-button {
  position: absolute;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  border: 0;
  color: white;
  background: transparent;
}

.ok-button {
  background-color: #2b87f0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.ok-button:hover {
  background-color: #1a66cc;
}
