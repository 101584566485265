.AiThinking {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  gap: 60px;
  padding-top: 60px;
  @keyframes MoveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
  &__imgWrapper {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: MoveUpDown 4s linear infinite;


    &__img {
      width: inherit;
      height: inherit;
    }
  }

  &__textsWrapper {
    display: flex;
    width: 100%;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &__yellowText {
      color: var(--fixed-color-yellow, #ffbe00);
      text-align: center;
      font-size: 18px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    &__SecondText {
      color: rgba(254, 254, 254, 0.6);
      text-align: center;
      font-size: 18px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 391px) {
  .AiThinking {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    gap: 40px;
    &__textsWrapper {
      width: 100%;
      max-width: 320px;

      &__yellowText {
        font-size: 16px;
        line-height: 24px;
      }

      &__SecondText {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}