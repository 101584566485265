* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.SecondForm {
    max-width: 444px;
    width: 100%;
    padding: 0px 10px;

    &-cont {
        display: flex;
        gap: 24px;
        width: 100%;

        > div {
            flex: 0 1 200px;
        }

        .input {
            // position: relative;
            margin-top: 16px;
            &:nth-child(1) {
                margin-top: 0;
            }
            > p {
                font-size: 13px;
                line-height: 20px;
                color: rgba(254, 254, 254, 0.6);
                margin-bottom: 8px;
            }

            .passwordInput {
                position: relative;
            }

            input {
                // background: #254365;
                outline: none;
                background: var(--accent-main, #20528B);
                border-radius: 8px;
                font-size: 14px;
                line-height: 22px;
                padding: 9px 16px;
                border: none;
                color: #fefefe;
                width: 100%;
            }

            > select {
                // background: #254365;
                background: var(--accent-main, #20528B);
                border-radius: 8px;
                font-size: 14px;
                padding: 16px;
                color: #fefefe;
                line-height: 22px;
                padding: 9px 16px;
                border: none;
                width: 100%;
            }


            &-password {
                position: relative;
            }

            &-passimg, &-confpassimg {
                cursor: pointer;
                position: absolute;
                top: calc(10px);
                right: 16px;
            }
        }
    }

    &__tag {
        margin-top: 16px;
        > p {
            color: rgba(254, 254, 254, 0.6);
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 8px;
        }

        > input {
            outline: none;
            // width: calc(100% - 24px);
            // max-width: calc(100% - 24px);
            width: 100%;
            // background: #254365;
            background: var(--accent-main, #20528B);
            border-radius: 8px;
            border: none;
            padding: 9px 16px;
            color: #fefefe;
        }

        &__container {
            display: flex;
            max-width: 400px;
            flex-wrap: wrap;

            > div {
                padding: 6px 12px;
                display: flex;
                border: 1px solid #254365;
                border-radius: 6px;
                justify-content: space-between;
                max-width: 140px;
                margin-top: 16px;
                cursor: pointer;

                > p {
                    font-weight: 500;
                    font-size: 13px;
                    margin-right: 14px;
                    line-height: 20px;
                    color: rgba(254, 254, 254, 0.6);
                }
            }
        }

        &__info {
            display: flex;
            margin-top: 8px;
            > img {
                margin-right: 8px;
            }
            > p {
                color: rgba(254, 254, 254, 0.6);
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
    &__level {
        margin-top: 24px;

        > p {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: rgb(254, 254, 254);
            opacity: 0.6;
        }
        &__cont {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;

            &__grades {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                position: relative;
                > div {
                    background: #44697e;
                    border-radius: 3.5px;
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                    width: 7px;
                    height: 7px;
                    margin-bottom: 16px;
                    position: absolute;
                    top: 7px;
                }
                .activeCircle {
                    border: 2px solid #07d3c4;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
                    background: #102c4c;
                    border-radius: 50%;
                    margin-top: -15px;
                    width: 30px;
                    height: 30px;
                }
                > p {
                    color: #07d3c4;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    margin-top: 41px;
                }
            }
        }
    }
    &__terms {
        margin-top: 24px;
        display: flex;
        align-items: center;
        > p {
            margin-left: 12px;
            color: rgba(254, 254, 254, 0.6);
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        a {
            color: rgba(254, 254, 254, 0.6);
            text-decoration: underline;
        }
    }
    &-navigation {
        display: flex;
        margin-top: 16px;
        gap: 24px;
        margin-bottom: 20px;

        > button {
            width: 100%;
            padding: 8px 0px;
            cursor: pointer;
        }

        &__back {
            border: 1px solid #07d3c4;
            border-radius: 8px;
            background: transparent;
            color: #07d3c4;
        }
        &__continue {
            background: #07d3c4;
            border-radius: 8px;
            color: #102c4c;
            border: none;
        }
    }
}

.disable {
    opacity: 0.6;
}