.About {
    $blue: #174273;
    background: $blue;
    &-content {
        padding: 60px 240px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__text {
            display: flex;
            flex-direction: column;

            &__title {
                color: #FEFEFE;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;

                >span {
                    color: #07D3C4;
                }
            }
            &__info {
                color: rgba(254, 254, 254, 0.6);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                max-width: 828px;
                margin-top: 16px;
            }
        }
    }
    &-stats {
        display: flex;
        padding: 0px 240px 66px;
        &-cont1 {
            display: flex;
            flex-direction: column;
            border-right: 1px solid #07D3C4;
            padding-right: 80px;
            &__numbers {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 100px;
                &:nth-child(1) {
                    margin-top: 0px;
                }
                >h2 {
                    color: #F4F4F4;
                    font-weight: 600;
                    font-size: 44px;
                    line-height: 48px;
                }
                >p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #A7B3C0;
                    margin-top: 8px;
                }
            }
        }
        &-cont2 {
            display: flex;
            flex-direction: column;
            &__text {
                margin-top: 20px;
                margin-left: 50px;
                &:nth-child(1) {
                    margin-top: 0px;
                }
                >h2 {
                    color: #FEFEFE;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                }
                >p {
                    color: rgba(254, 254, 254, 0.6);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 1919px) {
    .About {
        &-content, &-stats {
            padding-left: 100px;
            padding-right: 100px;
        }

        &-content {
            &__img {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            &__text {
                &__info {
                    max-width: 519px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .About {
        &-content, &-stats {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .About {
        &-content, &-stats {
            flex-direction: column;
        }

        &-content {
            row-gap: 24px;
            margin-bottom: 52px;


            &__img {
                img {
                    max-width: 216px;
                    height: auto;
                }
            }

            &__text__info {
                max-width: 100%;
            }

            &__text__title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &-stats {
            &-cont1 {
                border-right: none;
                border-bottom: 2px solid #07D3C466;
                padding: 0 0 52px;
                margin-bottom: 20px;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 80px;

                &__numbers {
                    margin-top: 0;
                    width: 50%;
                }
            }

            &-cont2 {
                &__text {
                    margin-left: 0;

                    >h2 {
                        margin-bottom: 8px;
                    }

                    &:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .About-content__text__info {
        font-size: 13px;
        line-height: 20px;
    }
}