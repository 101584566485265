.items {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f9f9f9;
  padding-top: 20px;
  padding-bottom: 50px;
  &__info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 20px;
    gap: 50px;
    max-width: 1080px;
    width: 100%;

    &__left {
      &__item {
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 16px;
        max-width: 350px;
        width: 100%;
        border: 1px solid rgba(7, 211, 196, 0.4);
        border-radius: 8px;
        margin-bottom: 16px;
        cursor: pointer;
        &__img-wrapper {
          width: 56px;
          height: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
          &__img {
            width: 28;
            height: 50;
          }
        }
        &__news {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          &__title {
            max-width: 246px;
            width: 100%;
            height: 24px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #07d3c4;
          }
          &__Price {
            max-width: 246px;
            width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: #102c4c;
            &__duration {
              font-size: 20px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              margin-left: 3px;
            }
          }
        }
      }
    }
    &__right {
      width: 100%;
      max-width: 680px;
      &__title {
        width: 100%;
        max-width: 680px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #102c4c;
        margin-bottom: 16px;
      }
      &__list-container {
        padding-left: 25px;
        position: relative;
        list-style-type: none;
        &__item {
          width: 100%;
          max-width: 680px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #102c4c;
          margin-bottom: 16px;
          &::before {
            content: "\2022"; /* Custom symbol for the marker */
            position: absolute;
            left: 10px;
            color: #102c4c;
          }
          &__hundreed-percent {
            width: 100%;
            max-width: 576px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #07d3c4;
            margin-top: 8px;
            margin-bottom: 24px;
          }
          &__more-text {
            width: 100%;
            max-width: 660px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #102c4c;
            opacity: 0.6;
            margin-top: 8px;
          }
          &__coming-soon {
            display: flex;
            height: 30px;
            align-items: flex-start;
            &__text {
              width: 100%;
              max-width: 103px;
              height: 30px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #254365;
              display: flex;
              align-items: center;
              background-color: rgba(7, 211, 196, 0.4);
            }
          }
        }
      }
      &__btn-container {
        display: flex;
        margin-top: 8px;
        margin-bottom: 20px;
        &__choose {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 6px;
          width: 100%;
          max-width: 170px;
          height: 40px;
          background: var(--accent-main, #20528B);
          border: 0;
          border-radius: 8px;
          margin-left: 25px;
          margin-right: 24px;
          cursor: pointer;

          .choose-text {
            width: 100%;
            max-width: 62px;
            height: 24px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #fefefe;
          }
        }
        &__serve-pass {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 6px;
          width: 100%;
          max-width: 170px;
          height: 40px;
          border: 1px solid #07d3c4;
          border-radius: 8px;
          background: transparent;
          cursor: pointer;

          &__serveText {
            width: 100%;
            max-width: 137px;
            height: 24px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #07d3c4;
          }
        }
      }
    }
  }
}

.isActiveItem {
  background: #fefefe;
  box-shadow: 0px 0px 20px rgba(16, 44, 76, 0.08);
  border: none;
}
@media screen and (max-width: 1024px){
  .items__info{
    justify-content: center;
  }
}
@media screen and (max-width: 360px) {
  .items {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 14px;
    max-width: 360px;
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0px;
      gap: 16px;
      width: 100%;
      max-width: 320px;
      padding-bottom: 0px;
      &__left {
        &__item {
          width: 100%;
          max-width: 320px;
          &__img-wrapper {
            width: 44px;
            height: 44px;
            &__img {
              width: 24px;
              height: 39px;
            }
          }
          &__news {
            &__title {
              width: 100%;
              max-width: 228px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #07d3c4;
            }
            &__Price {
              width: 100%;
              max-width: 142px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #174273;
              &__duration {
                font-size: 12px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                margin-left: 3px;
              }
            }
          }
        }
      }
      &__right {
        width: 100%;
        max-width: 320px;
        &__title {
          width: 100%;
          max-width: 320px;
        }
        &__list-container {
          &__item {
            width: 100%;
            max-width: 320px;
            &__hundreed-percent {
              margin-bottom: 16px;
              width: 100%;
              max-width: 296px;
            }
            &__more-text {
              width: 100%;
              max-width: 300px;
            }
          }
        }
        &__btn-container {
          display: flex;
          margin-top: 0px;
          flex-direction: column;
          width: 100%;
          max-width: 320px;
          &__choose {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 6px;
            max-width: 320px;
            width: 100%;
            height: 40px;
            background: #20528b;
            border-radius: 8px;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 16px;
          }
          &__serve-pass {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 6px;
            width: 100%;
            max-width: 320px;
            height: 40px;
            border: 1px solid #07d3c4;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

