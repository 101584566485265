.DateLowToHigh-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  max-width: 890px;
  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    max-width: 286px;
    &__items {
      width: 100%;
      max-width: 286px;
      display: flex;
      flex-direction: column;
      gap: 1px;
      &__dateCont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 8px 16px;
        gap: 10px;
        width: 100%;
        max-width: 286px;
        background: #20528b;
        border-radius: 8px 8px 0px 0px;
        box-sizing: border-box;
        &__date {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #fefefe;
        }
        &__duration {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #fefefe;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 12px;
        width: 100%;
        max-width: 286px;
        background: #20528b;
        border-radius: 0px 0px 8px 8px;
        box-sizing: border-box;
        &__topicCont {
          display: flex;
          gap: 8px;
          align-items: center;
          width: 100%;
          &__topic {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(254, 254, 254, 0.4);
          }
          &__subject {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #fefefe;
          }
        }
        &__mentorCont {
          display: flex;
          gap: 8px;
          align-items: center;
          width: 100%;
          &__mentor {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(254, 254, 254, 0.4);
          }
          &__mentorsName {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #fefefe;
          }
        }
        &__lane {
          width: 100%;
          max-width: 254px;
          height: 1px;
          background: #fefefe;
          opacity: 0.2;
          border-radius: 6px;
        }
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: rgba(254, 254, 254, 0.6);
        }
      }
    }
  }
}
.emptyMyFeedback-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-top: 194px;
  &__text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fefefe;
  }
  &__btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 31px;
    gap: 6px;
    width: 100%;
    max-width: 180px;
    height: 40px;
    border: 1px solid #07d3c4;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    &__text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #07d3c4;
    }
  }
}
@media screen and (max-width: 696px) {
  .DateLowToHigh-cont {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    &__column {
      width: 100%;
      max-width: 320px;
      &__items {
        width: 100%;
        max-width: 320px;
        &__dateCont {
          width: 100%;
          max-width: 320px;
        }
        &__info {
          width: 100%;
          max-width: 320px;
          &__lane{
            width: 100%;
            max-width: 288px;
          }
        }
      }
    }
  }
  .emptyMyFeedback-cont {
    gap: 24px;
    padding: 0px;
    justify-content: center;
    height:  calc(100vh - (56.8px + 16px + 48px));
    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
@media screen and (max-width: 360px) {
  .DateLowToHigh-cont {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 320px;
    &__column {
      width: 100%;
      max-width: 320px;
      &__items {
        width: 100%;
        max-width: 320px;
        &__dateCont {
          width: 100%;
          max-width: 320px;
        }
        &__info {
          width: 100%;
          max-width: 320px;
          &__lane{
            width: 100%;
            max-width: 288px;
          }
        }
      }
    }
  }
  .emptyMyFeedback-cont {
    gap: 24px;
    padding: 0px;
    justify-content: center;
    height:  calc(100vh - (56.8px + 16px));
    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
