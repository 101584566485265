.videoplayer{
  &__videoContainer{
    margin-top: 20px;
    position: relative;
    text-align: center;
    height: 560px;
  }           
  &__playIcon{
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 50%;    
    left: 50%;
    transform:translate(-50%, -50%);
    cursor:pointer;
    z-index: 2;
  }
  &__video {
    margin: 0px auto;
    border-radius: 30px;
    height: 560px;
    object-fit: cover;
  }
}         





