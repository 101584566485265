label {
  display: block;
  position: relative;
  padding-left: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    input ~ .checkmark {
      border-color: #07d3c4;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark:after {
      display: block;
    }

    &:checked ~ .checkmark {
      border-color: #07d3c4;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid;
    border-color: rgba(254, 254, 254, 0.4);

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid #07d3c4;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.checkbox {
  &-error {
    color: #f56565;
  }
}

// we will need to phase out the old styles above

/* Checkbox.scss */

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;

  .checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #2d3e50; /* Default text color */
  }

  .checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: #f1f1f1;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
    transition: all 0.2s ease;
  }

  .checkbox-input:checked ~ .checkmark {
    background-color: #00b9a5; /* Checked background color */
    border-color: #00b9a5; /* Checked border color */
  }

  .checkbox-input:focus-visible ~ .checkmark {
    outline: 2px solid #00b9a5; /* Focus outline for accessibility */
  }

  .checkmark--error {
    border-color: #f56565; /* Red border for error */
    background-color: #ffe6e6; /* Light red background for error */
  }
}
