* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.FirstForm {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 444px;
    padding: 0 10px;
    width: 100%;
    &__error {
        color: var(--fixed-color-red, #FF002F);
        font-size: 13px;
        line-height: 20px;
        display: flex;
        margin-top: 8px;

        img {
            margin-right: 8px;
        }
    }
    &-cont {
        display: flex;
        gap: 24px;

        > div {
            flex: 0 1 200px;
        }

        .input {
            margin-top: 16px;
            &:nth-child(1) {
                margin-top: 0;
            }
            > p {
                font-size: 13px;
                line-height: 20px;
                color: rgba(254, 254, 254, 0.6);
                margin-bottom: 8px;
            }
            > input {
                // background: #254365;
                outline: none;
                background: var(--accent-main, #20528B);
                border-radius: 8px;
                font-size: 14px;
                line-height: 22px;
                padding: 9px 16px;
                border: none;
                color: #fefefe;
                width: 100%;
            }
            > select {
                // background: #254365;
                background: var(--accent-main, #20528B);
                border-radius: 8px;
                font-size: 14px;
                padding: 16px;
                color: #fefefe;
                line-height: 22px;
                padding: 9px 16px;
                border: none;
                width: 100%;
            }
        }
    }

    &__tag {
        margin-top: 16px;
        // margin-right: -10px;

        > p {
            color: rgba(254, 254, 254, 0.6);
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 8px;
        }

        > input {
            // width: calc(100% - 24px);
            // max-width: calc(100% - 24px);
            width: 100%;
            // background: #254365;
            outline: none;
            background: var(--accent-main, #20528B);
            border-radius: 8px;
            border: none;
            padding: 9px 16px;
            color: #fefefe;
        }
        > select {
            // width: calc(100% - 24px);
            // max-width: calc(100% - 24px);
            width: 100%;
            // background: #254365;
            background: var(--accent-main, #20528B);
            border-radius: 8px;
            border: none;
            padding: 9px 16px;
            color: #fefefe;
        }

        &__container {
            display: flex;
            max-width: 400px;
            flex-wrap: wrap;
            img {
                width: 24px;
            }
            > div {
                padding: 6px 12px;
                display: flex;
                border: 1px solid #254365;
                border-radius: 6px;
                justify-content: space-between;
                max-width: 140px;
                margin-top: 16px;
                cursor: pointer;
                align-items: center;
                > p {
                    font-weight: 500;
                    font-size: 13px;
                    margin-right: 14px;
                    line-height: 20px;
                    color: rgba(254, 254, 254, 0.6);
                }
            }
        }

        &__info {
            display: flex;
            margin-top: 8px;
            > img {
                margin-right: 8px;
            }
            > p {
                color: rgba(254, 254, 254, 0.6);
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
    &-navigation {
        display: flex;
        margin-top: 16px;
        // margin-right: -16px;
        flex-grow: 1;
        flex-direction: column;
        margin-bottom: 20px;

        // > button {
        //     width: 100%;
        //     padding: 8px 0px;
        //     cursor: pointer;
        // }

        > div {
            gap: 24px;
            display: flex;
            justify-content: space-between;
        }

        button {
            // width: 100%;
            padding: 8px 0px;
            cursor: pointer;
            flex-grow: 1;
            flex-basis: 200px;
        }

        &__back {
            border: 1px solid #07d3c4;
            border-radius: 8px;
            background: transparent;
            color: #07d3c4;
        }
        &__continue {
            background: #07d3c4;
            border-radius: 8px;
            color: #102c4c;
            border: none;
        }
    }
}

.displayFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.marginBottom {
    margin-bottom: 48px;
}

@media (max-width: 360px) {
    .FirstForm {
        &-navigation {
            justify-content: flex-end;
        }
    }
}

@media (max-width: 301px) {
    .marginBottom {
        margin-bottom: 48px;
    }
}