.myPlanCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 24px;

  &__btnWrapper {
    display: flex;
    width: 100%;
    // padding-left: 24px;
    // padding-right: 24px;

    &__btn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      gap: 6px;
      width: 100%;
      max-width: 170px;
      border: 1px solid #fefefe;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;

      &__text {
        width: 100%;
        max-width: 138px;
        font-family: "Poppins";
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #fefefe;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;

    &__priceCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 16px;
      width: 100%;
      max-width: 300px;
      background: #20528b;
      box-shadow: 0px 0px 20px rgba(16, 44, 76, 0.08);
      border-radius: 8px;
      box-sizing: border-box;

      &__imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;

        &__img {
          width: 29.22px;
          height: 100%;
        }
      }

      &__priceInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 2px;
        width: 100%;
        max-width: 204px;

        &__title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #07d3c4;
        }

        &__price {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 48px;
          color: #fefefe;

          &__index {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #fefefe;
            margin-left: 5px;
          }
        }
      }
    }

    &__descriptionCont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      &__noContent {
        color: rgba(254, 254, 254, 0.6);
        font-size: 14px;
        padding: 15px 0px;
      }

      &__title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #fefefe;
      }

      &__textCont {
        display: flex;
        flex-direction: column;
        padding-left: 22px;
        position: relative;
        list-style-type: none;

        &__text {
          width: 100%;
          max-width: 680px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(254, 254, 254, 0.6);
          margin-bottom: 16px;

          &::before {
            content: "\2022";
            /* Custom symbol for the marker */
            position: absolute;
            left: 7px;
            color: rgba(254, 254, 254, 0.6);
          }

          &__hundreedPercent {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #fefefe;
            margin-top: 8px;
          }

          &__ComingSoon {
            display: flex;
            height: 30px;
            align-items: flex-start;
            margin-top: 24px;
            margin-bottom: 8px;

            &__text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #fefefe;
              height: 30px;
              background: rgba(7, 211, 196, 0.4);
              width: 100%;
              max-width: 103px;
              padding-top: 4px;
              padding-bottom: 4px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  .lessMargin {
    margin-bottom: 0px;
  }
}

.activePlatext__hide {
  display: none;
}

.profileCont__contentCont {
  &__activePlanText {
    margin-top: 48px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
    width: 100%;
    max-width: 1024px;
  }
}

@media screen and (max-width: 1024px) {
  .myPlanCont {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    max-width: 1024px;
    // height: calc(100vh - (32px + 24px + 56.8px));
    justify-content: space-between;

    &__item {
      width: 100%;
      max-width: 1024px;

      &__priceCont {
        width: 100%;
        max-width: 1024px;

        &__priceInfo {
          width: 100%;
          max-width: 224px;
        }
      }

      &__descriptionCont {
        width: 100%;
        max-width: 1024px;

        &__title {
          font-size: 18px;
          line-height: 28px;
        }

        &__textCont {
          &__text {
            width: 100%;
            max-width: 1024px;
            font-size: 13px;
            line-height: 20px;

            &__hundreedPercent {
              font-size: 13px;
              line-height: 20px;
            }

            &__ComingSoon {
              margin-bottom: 12px;
            }
          }
        }
      }
    }

    &__btnWrapper {
      width: 100%;
      max-width: 1024px;
      padding: 0px;
      gap: 24px;
      margin-top: auto;
      margin-bottom: 20px;

      &__btn {
        width: 100%;
        max-width: 1024px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .profileCont__contentCont__activePlanText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
  }

  .myPlanCont {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    max-width: 320px;
    height: calc(100vh - (32px + 24px + 56.8px));
    justify-content: space-between;

    &__item {
      width: 100%;
      max-width: 320px;

      &__priceCont {
        width: 100%;
        max-width: 320px;

        &__priceInfo {
          width: 100%;
          max-width: 224px;
        }
      }

      &__descriptionCont {
        width: 100%;
        max-width: 320px;

        &__title {
          font-size: 18px;
          line-height: 28px;
        }

        &__textCont {
          &__text {
            width: 100%;
            max-width: 320px;
            font-size: 13px;
            line-height: 20px;

            &__hundreedPercent {
              font-size: 13px;
              line-height: 20px;
            }

            &__ComingSoon {
              margin-bottom: 12px;
            }
          }
        }
      }
    }

    &__btnWrapper {
      width: 100%;
      max-width: 320px;
      padding: 0px;
      gap: 24px;
      margin-top: auto;
      margin-bottom: 20px;

      &__btn {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}

@media screen and (max-width: 324px) {
  .profileCont__contentCont__activePlanText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
  }

  .myPlanCont {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    max-width: 320px;
    height: calc(100vh - (32px + 24px + 56.8px));
    justify-content: space-between;

    &__item {
      width: 100%;
      max-width: 320px;

      &__priceCont {
        width: 100%;
        max-width: 320px;
        align-items: flex-start;

        &__priceInfo {
          width: 100%;
          max-width: 224px;
        }
      }
    }
  }
}