.Question {
  background: #174273;
  min-height: calc(100vh - 86px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-cont,
  &-cont-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 177px);
    width: 100%;
    &__emptyimg {
      width: 20%;
    }
    &__img {
      width: 20%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        color: #ffbe00;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h1 {
        color: var(--text-color-main, #fefefe);
        text-align: center;
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
      }

      &__text {
        margin-top: 20px;
        color: #dddddd;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      > h3 {
        color: #fefefe;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          margin-left: 6px;
        }
      }

      &__info {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #ffbe00;
        margin-top: 20px;
        text-align: center;
      }

      &__select {
        display: flex;
        gap: 3px;
        margin-top: 32px;
        width: 60%;

        > p {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #dddddd;
          width: 64px;
        }

        > div {
          background: #20528b;
          width: 79px;
          height: 44px;
          display: flex;
          flex-grow: 1;
          flex-basis: 79px;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          color: #fefefe;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;

          &:first-of-type {
            border-radius: 8px 0px 0px 8px;
          }

          &:last-of-type {
            border-radius: 0px 8px 8px 0px;
          }
        }
      }

      &__select-cont {
        display: flex;
        max-width: 1100px;
        flex-wrap: wrap;
        margin-top: 24px;
        gap: 40px;
        justify-content: center;

        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &-title {
            color: #fefefe;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }

          &-cont {
            display: flex;
            margin-top: 8px;
            gap: 3px;

            > div {
              background: #20528b;
              width: 79px;
              height: 44px;
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              color: #fefefe;
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;

              &:first-of-type {
                border-radius: 8px 0px 0px 8px;
              }

              &:last-of-type {
                border-radius: 0px 8px 8px 0px;
              }
            }
          }
        }
      }
    }
  }

  .answered {
    border: 2px solid var(--accent-brand, #07d3c4);
  }
}

.question-cont-mobile-cont {
  display: none;
}

@media (max-width: 1024px) {
  .question-cont-mobile-cont {
    display: flex;
    flex-direction: column;
    // margin-left: 10px;
    // margin-right: 10px;

    &__header {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
    }
  }

  .Question {
    align-items: initial;
    .Question-cont {
      &-desktop {
        display: none;
      }
    }

    &-cont {
      width: 100%;
      height: max-content;
      padding-top: 16px;

      &__img {
        width: auto;
        img {
          width: 100px;
          height: 100px;
        }

        > p {
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      &__content {
        margin-bottom: 20px;
        > h1 {
          font-size: 24px;
          line-height: 36px;
        }

        // &__text {
        //   display: none;
        // }

        &__wrapper {
          display: none;
        }

        > h3 {
          display: none;
        }

        &__info {
          display: none;
        }

        &__select {
          display: none;
        }
      }
    }
  }

  .question-cont-mobile {
    padding: 0px 10px;
    display: flex;
    // width: 60%;
    padding-bottom: 112px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
      margin-top: 16px;
      color: rgba(254, 254, 254, 0.6);
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }

    > h3 {
      color: #fefefe;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        margin-left: 6px;
      }
    }

    &__info {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #ffbe00;
      margin-top: 20px;
      text-align: center;
    }

    &__select {
      // width: 60%;
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-top: 32px;
      align-items: center;
      width: 100%;
      > div:last-child {
        display: flex;
        justify-content: space-between;
        width: 100%;

        > p {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.4);
          width: 64px;
        }

        > p:last-child {
          text-align: right;
        }
      }
    }

    &__onlyselect {
      gap: 3px;
      display: flex;

      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 3px;
    }

    &__onlyselect > div {
      display: flex;
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
      height: 44px;
      color: #fefefe;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-grow: 1;
      flex-shrink: 1;
      background: var(--accent-main, #20528b);
      box-sizing: border-box;
      border: transparent;
      cursor: pointer;

      &:first-of-type {
        border-radius: 8px 0px 0px 8px;
      }

      &:last-of-type {
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
}
@media (max-width: 391px) {
  .Question-cont {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    &__content {
      &__select-cont {
        width: 100%;
        max-width: 320px;
        gap: 16px;
        .item {
          width: 100%;
          .item-cont {
            width: 100%;
          }
        }
      }
    }
  }
  // .Question-cont__content__select-cont{
  //     width: 100%;
  //     max-width: 320px;
  // }
}
@media (max-width: 360px) {
  .Question-cont {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    &__content {
      &__select-cont {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
  }
  // .Question-cont__content__select-cont{
  //     width: 100%;
  //     max-width: 320px;
  // }
}
