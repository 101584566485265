.feedback-cont {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  isolation: isolate;
  max-width: 415px;
  width: 100%;
  background: #174273;
  border: 1px solid #07d3c4;
  box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.5);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  &__main-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;
    max-width: 367px;
    width: 100%;
    &__imgWrapper {
      width: 100%;
      max-width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__img {
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      max-width: 281px;
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #fefefe;
    }
    &__description {
      width: 100%;
      max-width: 367px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: rgba(254, 254, 254, 0.6);
    }
    &__recomend {
      max-width: 360px;
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: rgba(254, 254, 254, 0.6);
      margin-bottom: 12px;
    }
    &__rate {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14px;
      max-width: 360px;
      width: 100%;
      &__circle-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 2px 5px;
        gap: 2px;
        isolation: isolate;
        max-width: 360px;
        width: 100%;
        height: 11px;
        background: #20528b;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        position: relative;
        &__circle {
          width: 7px;
          height: 7px;
          background: rgba(254, 254, 254, 0.4);
          border-radius: 3.5px;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        &__slider {
          margin: 0;
          left: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          gap: 2px;
          isolation: isolate;
          width: 100%;
          height: 11px;
          background: transparent;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
          border-radius: 20px;
          position: absolute;
          accent-color: #20528b;
          outline: none;
          border: none;
          appearance: none;
        }
      }
      &__num-cont {
        max-width: 360px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 2px 5px;
        gap: 2px;
        isolation: isolate;
        &__number {
          width: 8px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #07d3c4;
        }
      }
    }
  }
  &__thirt-part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    max-width: 367px;
    width: 100%;
    &__btn-cont {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      max-width: 367px;
      width: 100%;
      &__cansel {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 68px 8px 67px;
        gap: 6px;
        max-width: 171.5px;
        width: 100%;
        height: 40px;
        border: 1px solid #07d3c4;
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #07d3c4;
        }
      }
      &__send {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 68px 8px 67px;
        gap: 6px;
        width: 100%;
        max-width: 171.5px;
        height: 40px;
        background: #07d3c4;
        border-radius: 8px;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #174273;
        }
      }
    }
  }
  #feedback_slider::-webkit-slider-thumb {
    appearance: none;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    background: #20528b;
    border: 2px solid #07d3c4;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    flex: none;
    order: 9;
    flex-grow: 0;
    z-index: 9;
    border-radius: 50%;
    cursor: pointer;
  }
}
