.Scenario4Intro-cont {
  height: calc(100vh - (92.8px + 92px));
  background-color: #174273;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  &__textCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    &__title {
      color: var(--text-color-main, #fefefe);
      text-align: center;
      font-family: Staatliches;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 150% */
    }
    &__text {
      color: var(--text-color-60, rgba(254, 254, 254, 0.6));
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
}
