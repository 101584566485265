$blue: #174273;

.Home {
  display: flex;
  flex-direction: column;

  &-welcome {
    background: $blue;
    padding-bottom: 20px;
    &-content {
      display: flex;
      align-items: center;
      margin-inline: auto;
      padding: 40px 0 20px;
      max-width: calc(100vw - (240px * 2));
      column-gap: 25px;
      justify-content: space-between;
      padding-bottom: 0px;
      &-text {
        max-width: 800px;

        &__title {
          > h1 {
            color: #fefefe;
            font-weight: 600;
            font-size: 40px;
            line-height: 60px;
            column-gap: 11px;
            max-width: 662px;

            > span {
              position: static;
              display: inline-block;
              transform: translate(-13%, -16px);

              margin-left: 16px;
              color: #07d3c4;
              background-image: url("../../assets/circle.svg");
              padding: 14px 25px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100% 100%;
            }
          }
        }
        > p {
          max-width: 800px;
          margin-top: 0;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #eeeeee;
        }
        > button {
          margin-top: 44px;
          background: #fefefe;
          border-radius: 8px;
          border: none;
          color: $blue;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
          line-height: 24px;
          padding: 10px 42px;
        }
      }
      &__img {
        img {
          max-width: 100%;
          height: auto;
        }

        > p {
          text-align: center;
          color: #fefefe;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          margin-top: 12px;
          > span {
            color: #ffbe00;
          }
        }
      }
    }
  }
  .waveimg {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &-content {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;

    &-items {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    > h3 {
      color: $blue;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 10px;
    }
    .item {
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        > img {
          margin-right: 30px;
        }
        > div {
          margin-left: 30px;

          > img {
            left: -26px;
          }
        }
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        > img {
          margin-left: 30px;
        }
        > div {
          margin-right: 30px;

          > img {
            right: -26px;
          }
        }
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;
        > div {
          > img {
            left: inherit;
          }
        }
      }

      > img {
        width: 100%;
        max-width: 300px;
      }
      > div {
        max-width: 540px;
        background: #e9e9ff;
        border-radius: 0px 16px 16px 16px;
        padding: 30px;
        position: relative;
        > p {
          color: #000000;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          > span {
            font-weight: 600;
          }
        }
        > img {
          position: absolute;
          top: 0;
          left: -26px;
          width: 58px;
        }
      }
    }
    .Home-content-wave {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1919px) {
  .Home {
    &-welcome {
      &-content {
        max-width: calc(100vw - (100px * 2));

        &-text__title > h1 {
          max-width: 576px;
          margin-bottom: 32px;

          > span {
            transform: translate(0px, -15px);
            position: absolute;
          }
        }
      }
    }

    &-content {
      max-width: calc(100vw - (190px * 2));

      .item {
        > img {
          max-width: 240px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .Home {
    &-welcome {
      &-content {
        max-width: calc(100vw - (50px * 2));
        &-text {
          max-width: 50%;

          &__title {
            > h1 {
              font-size: 35px;
              > span {
                position: static;
                transform: translate(0, 0);
              }
            }
          }

          > p {
            font-size: 16px;
          }
        }
      }
    }

    &-content {
      max-width: calc(100vw - (100px * 2));
    }
  }
}

@media screen and (max-width: 768px) {
  .Home {
    &-welcome {
      &-content {
        max-width: calc(100vw - (20px * 2));
        flex-direction: column;
        &-text {
          max-width: 100%;
          &__title {
            > h1 {
              font-size: 35px;
              > span {
                position: static;
                transform: translate(0, 0);
              }
            }
          }

          > p {
            font-size: 16px;
          }
        }
      }
    }

    &-content {
      max-width: calc(100vw - (20px * 2));

      &-wave {
        display: none;
      }

      .item,
      .item:nth-child(2n) {
        flex-direction: column-reverse;
        row-gap: 43px;
      }

      .item {
        &:nth-child(odd),
        &:nth-child(even) {
          > img {
            margin-left: 0;
            margin-right: 0;
          }
        }

        &:nth-child(odd) > div,
        &:nth-child(even) > div {
          margin: 0;
          display: flex;
          flex-direction: column;

          > img {
            top: 100%;
            bottom: 0;
            left: 50%;
            right: 50%;
            transform: translate(-50%, 0);
          }
        }

        > div {
          max-width: 100%;
        }

        &-link {
          text-align: center;
          float: none;
        }
      }

      &-items {
        row-gap: 17px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Home {
    &-welcome {
      &-content {
        row-gap: 20px;
        &-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > p {
            text-align: center;
            line-height: 24px;
          }

          > button {
            margin-top: 20px;
            text-align: center;
          }

          &__title {
            > h1 {
              font-size: 24px;
              line-height: 36px;
              text-align: center;
              margin-bottom: 16px;

              span {
                padding: 6px;
                background-size: 154px 48px;
              }
            }
          }
        }
      }
    }
    .Home-content-wave {
      display: none;
    }
  }
}
