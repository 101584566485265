.softServe-cont {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 100%;
  max-width: 415px;
  background: #174273;
  border: 1px solid #07d3c4;
  box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.5);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  &__imgWrapper {
    width: 100%;
    max-width: 162px;
    height: 100px;
    &__img {
      width: 100%;
      height: 100%;
    }
  }
  &__text-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    max-width: 367px;
    width: 100%;
    flex: none;
    order: 1;
    &__title {
      max-width: 156px;
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #fefefe;
    }
    &__description {
      max-width: 367px;
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: rgba(254, 254, 254, 0.6);
    }
  }
  &__children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    max-width: 367px;
    order: 3;
  }
  &__btn-cont {
    width: 100%;
    max-width: 367px;
    order: 8;
    &__btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      gap: 6px;
      width: 100%;
      height: 44px;
      background: #07d3c4;
      border-radius: 8px;
      cursor: pointer;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #174273;
      }
    }
  }
}
