.profileCont__settingsCont__textWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  &__UserName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fefefe;
  }
  &__profileInfo {
    display: none;
  }
}
.Setting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
  max-width: 200px;
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    width: 100%;
    max-width: 200px;
    background: #20528b;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    &__imgWrapper {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__img {
        width: inherit;
        height: inherit;
      }
    }
    &__title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #fefefe;
    }
  }
}
.profileCont__settingsCont__log-out {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: rgba(254, 254, 254, 0.6);
  cursor: pointer;
}
.activeSettingText {
  color: #07d3c4;
}
.disableSetting {
  opacity: 0.6;
  cursor: inherit;
}
.activeSetting {
  border-right: 2px solid #07d3c4;
}
@media screen and (max-width: 1024px) {
  .profileCont__settingsCont__textWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 1024px;
    &__UserName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #fefefe;
    }
    &__profileInfo {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: rgba(254, 254, 254, 0.6);
      display: block;
    }
  }
  .Setting {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    max-width: 1024px;
    border-radius: 8px;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 12px;
      width: 100%;
      max-width: 1024px;
      background: #20528b;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .profileCont__settingsCont__log-out{
    text-align: left;
    width: 100%;
    max-width: 1024px;
  }
}
@media screen and (max-width: 360px) {
  .profileCont__settingsCont__textWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    &__UserName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #fefefe;
    }
    &__profileInfo {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: rgba(254, 254, 254, 0.6);
      display: block;
    }
  }
  .Setting {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    max-width: 320px;
    border-radius: 8px;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 12px;
      width: 100%;
      max-width: 320px;
      background: #20528b;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
