.ProfileInfoCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  &__passwordCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &__content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px 0px;
      gap: 12px;
      width: 100%;
      max-width: 241px;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #07d3c4;
        cursor: pointer;
      }
      &__verticalLane {
        width: 1px;
        height: 20px;
        background: rgba(7, 211, 196, 0.4);
        border-radius: 6px;
      }
    }
  }
  &__userCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;
    width: 100%;
    background: #20528b;
    border-radius: 8px;
    box-sizing: border-box;
    &__identity {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0px;
      gap: 24px;
      width: 100%;
      max-width: 315px;
      &__imgWrapper {
        box-sizing: border-box;
        width: 74px;
        height: 74px;
        position: relative;
        border: 1px solid #07d3c4;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &__img {
          width: 100%;
          height: 100%;
        }
        &__chnageAvatar {
          position: absolute;
          width: 24px;
          height: 24px;
          right: 0px;
          top: 50px;
          background: #07d3c4;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        max-width: 210px;
        &__name {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
        }
        &__email {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    &__additional {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      &__key {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(254, 254, 254, 0.4);
      }
      &__value {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #fefefe;
      }
    }
  }
  .mainImg {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #07d3c4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }
}
@media screen and (max-width: 360px) {
  .ProfileInfoCont {
    margin-top: 0px;
    &__passwordCont {
      justify-content: center;
    }
    &__userCont {
      width: 100%;
      max-width: 320px;
      &__identity {
        width: 100%;
        max-width: 282px;
        gap: 10px;
        justify-content: space-between;
        &__info{
          width: 100%;
          max-width: 184px;
        }

      }
      &__additional {
        width: 100%;
        max-width: 288px;
      }
    }
  }
  .column{
    display: flex;
    flex-direction: column;
  }
}
