.InviteFriendModal-cont {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.0902, 0.2588, 0.4510, 0.3);
  overflow: auto;
  &__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    width: 100%;
    max-width: 415px;
    background: #174273;
    border: 1px solid #07d3c4;
    box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.5);
    border-radius: 8px;
    &__imgWrapper {
      width: 162px;
      height: 100px;
      &__img {
        width: 100%;
        height: 100%;
      }
    }
    &__textCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 12px;
      width: 100%;
      max-width: 367px;
      &__title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #fefefe;
      }
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: rgba(254, 254, 254, 0.6);
      }
    }
    &__emailInpCont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      max-width: 367px;
      &__emailText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(254, 254, 254, 0.6);
      }
      &__inp {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 9px 16px;
        gap: 16px;
        width: 100%;
        max-width: 367px;
        background: #20528b;
        border-radius: 8px;
        box-sizing: border-box;
        outline: none;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(254, 254, 254, 0.6);
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.6);
        }
      }
    }
    &__textAreaCont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      max-width: 367px;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(254, 254, 254, 0.6);
      }
      &__textArea {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 9px 16px;
        gap: 16px;
        width: 100%;
        height: 115px;
        max-width: 367px;
        background: #20528b;
        border-radius: 8px;
        box-sizing: border-box;
        border: none;
        outline: none;
        resize: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #fefefe;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px;
        }
         
        &::-webkit-scrollbar-track {
            background-color: transparent;
            -webkit-border-radius: 10px;
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: #6d6d6d; 
        }
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #fefefe;
        }
      }
    }
    &__btnCont {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;
      max-width: 367px;
      &__firstBtn {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 82px 8px 83px;
        gap: 6px;
        width: 100%;
        max-width: 171.5px;
        height: 40px;
        border: 1px solid #07d3c4;
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #07d3c4;
        }
      }
      &__secondBtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 68px 8px 67px;
        gap: 6px;
        width: 100%;
        max-width: 171.5px;
        height: 40px;
        box-sizing: border-box;
        background: #07d3c4;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #174273;
        }
      }
    }
  }
}
@media screen and (max-width: 500px){
  .InviteFriendModal-cont{
    padding: 10px;
    &__content{
      max-width: 320px;
      width: 100%;
      padding: 16px;
      gap: 16px;
      &__textCont{
        width: 100%;
        max-width: 272px;
      }
      &__emailInpCont{
        width: 100%;
        max-width: 288px;
        &__inp{
          width: 100%;
          max-width: 288px;
        }
      }
      &__textAreaCont {
        width: 100%;
        max-width: 288px;
        &__textArea{
          width: 100%;
          max-width: 288px;
        }
      }
      &__btnCont{
        width: 100%;
        max-width: 288px;
        gap: 12px;
        &__firstBtn{
          width: 100%;
          max-width: 138px;
          padding: 0px;
          justify-content: center;
        }
        &__secondBtn{
          width: 100%;
          max-width: 288px;
          padding: 0px;
          justify-content: center;
        }
      }
    }
  }
}
@media screen and (max-width: 360px){
  .InviteFriendModal-cont{
    padding: 10px;
    &__content{
      max-width: 320px;
      width: 100%;
      padding: 16px;
      gap: 16px;
      &__textCont{
        width: 100%;
        max-width: 272px;
      }
      &__emailInpCont{
        width: 100%;
        max-width: 288px;
        &__inp{
          width: 100%;
          max-width: 288px;
        }
      }
      &__textAreaCont {
        width: 100%;
        max-width: 288px;
        &__textArea{
          width: 100%;
          max-width: 288px;
        }
      }
      &__btnCont{
        width: 100%;
        max-width: 288px;
        gap: 12px;
        &__firstBtn{
          width: 100%;
          max-width: 138px;
          padding: 0px;
          justify-content: center;
        }
        &__secondBtn{
          width: 100%;
          max-width: 288px;
          padding: 0px;
          justify-content: center;
        }
      }
    }
  }
}
