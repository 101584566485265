.EditPasswordCont {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  &__title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
    width: 100%;
    max-width: 424px;
  }
  &__content {
    display: flex;
    align-items: flex-end;
    padding: 0px;
    gap: 24px;
    width: 100%;
    max-width: 424px;
    &__items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      width: 100%;
      max-width: 200px;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(254, 254, 254, 0.6);
      }
      &__inpWrapper {
        width: 100%;
        max-width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #20528b;
        border-radius: 8px;
        outline: none;
        border: none;
        color: rgba(254, 254, 254, 0.6);
        padding-right: 10px;
        box-sizing: border-box;
        &__inp {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 9px 16px;
          gap: 16px;
          width: 100%;
          max-width: 183px;
          background: #20528b;
          border-radius: 8px;
          outline: none;
          border: none;
          color: rgba(254, 254, 254, 0.6);
          box-sizing: border-box;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.6);
          &::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(254, 254, 254, 0.6);
          }
        }
        &__eye {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
  &__btnCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px 0px 0px;
    gap: 24px;
    width: 100%;
    max-width: 424px;
    box-sizing: border-box;
    &__btnCancel {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 100%;
      max-width: 200px;
      height: 40px;
      border: 1px solid #20528b;
      border-radius: 8px;
      cursor: pointer;
      background-color: transparent;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(254, 254, 254, 0.6);
      }
    }
    &__btnSaveChange {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 100%;
      width: 200px;
      height: 40px;
      border-color: transparent;
      background: #07d3c4;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #174273;
      }
    }
  }
  .confirmationError {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
    max-width: 424px;
    &__Text {
      color: var(--fixed-color-red, #ff002f);
      font-size: 13px;
      line-height: 20px;
      display: flex;
      font-family: "Poppins";
    }
  }
}
@media screen and (max-width: 1024px) {
  .EditPasswordCont__btnCont {
    min-height: calc(100vh - (56.8px + 48px + 16px + 68px + 16px));
    padding-bottom: 20px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 360px) {
  .EditPasswordCont {
    margin-top: 0px;
    width: 100%;
    max-width: 320px;
    justify-content: flex-start;
    height: 100%;
    padding-bottom: 20px;
    &__title {
      margin-bottom: 0px;
    }
    &__content {
      width: 100%;
      max-width: 320px;
      gap: 12px;
      &__items {
        width: 100%;
        max-width: 154px;
        &__inpWrapper {
          width: 100%;
          max-width: 154px;
          &__inp {
            width: 100%;
            max-width: 154px;
          }
        }
      }
    }
    &__btnCont {
      width: 100%;
      max-width: 320px;
      height: 100%;
      padding: 0px;
      min-height: calc(100vh - (56.8px + 48px + 16px + 68px + 16px));
      &__btnCancel {
        width: 100%;
        max-width: 154px;
      }
      &__btnSaveChange {
        width: 100%;
        max-width: 154px;
        padding: 8px 8px;
      }
    }
  }
}
// height: calc(100vh - (48px + 90px + 16px + (5 * 68px) + 56.8px));
