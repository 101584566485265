.profileCont {
  height: calc(100vh - 92.8px);
  // min-height: 652px;
  // height: 100%; this for scroll when modal opened
  overflow-x: hidden;
  background: #174273;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 25px;

  &__studentResources {
    padding: 25px 0;
    width: 100%;

    &__slider {
      position: relative;

      &Loading {
        position: absolute;
        top: 50%;
        display: block;
        transform: translateX(-50%);
        right: -50px;

        .loader-container {
          transform: rotate(90deg);
          height: auto;
        }
      }

      &-arrows {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &-icon {
          background-color: transparent;
          border: 0px;
        }
      }

      .slick-track {
        margin-left: unset;
      }
    }

    .slick-disabled {
      display: none;
    }

    .slick-initialized .slick-slide {
      padding: 0px 50px 0 0;
    }

    .slick-arrow {
      &::before {
        content: unset;
      }
    }

    .slick-next {
      right: -25px;
    }

    .slick-prev {
      transform: rotate(180deg);
      left: -40px;
    }

    &__row {
      display: flex;
      gap: 25px;
    }

    &__grid {
      width: 25%;
      border-radius: 16px;
      background-color: #fff;
      cursor: pointer;

      &:focus-visible {
        outline: 0px;
      }

      img {
        max-width: 100%;
        border-radius: 16px 16px 0 0;
        height: 130px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

    }

    &__info {
      padding: 20px 10px;

      h4 {
        font-size: 16px;
        font-weight: bold;
        color: #174273;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h5 {
        font-size: 16px;
        font-weight: 500;
        color: #174273;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 25px;
      color: #fff;
    }
  }

  &__grid {
    display: flex;
    gap: 30px;
    width: 100%;
  }

  &__settingsCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    max-width: 200px;
    margin-top: 40px;
  }

  &__contentCont {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    // max-width: 890px;
    width: 100%;
    gap: 16px;
  }

  .hide {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .profileCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    gap: 0px;
    height: calc(100vh - 72.8px);
    overflow-x: hidden;
    background: #174273;
    box-sizing: border-box;

    &__settingsCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 24px;
      width: 100%;
      max-width: 1024px;
      margin-top: 24px;
    }

    &__contentCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 16px;
      width: 100%;
      max-width: 1024px;
      margin-top: 16px;
    }
  }

  .hide {
    display: none;
  }

  .bigHeight {
    height: calc(100vh - 56.8px);
  }
}

@media screen and (max-width: 360px) {
  .profileCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 360px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 0px;
    height: calc(100vh - 72.8px);
    overflow-x: hidden;
    background: #174273;
    box-sizing: border-box;

    &__settingsCont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;
      max-width: 320px;
      margin-top: 24px;
    }

    &__contentCont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      width: 100%;
      max-width: 360px;
      margin-top: 16px;
    }
  }

  .hide {
    display: none;
  }

  .bigHeight {
    height: calc(100vh - 56.8px);
  }
}