.container {
  padding: 15px;
}

.ThankYouSignUp-cont {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.0902, 0.2588, 0.451, 0.3);
  overflow: auto;

  &__btnCont {
    &__text {
      color: var(--accent-dark, #174273);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    background: var(--accent-brand, #07d3c4);
    width: 100%;
    border: none;
    cursor: pointer;
  }

  &__content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--accent-brand, #07d3c4);
    background: var(--accent-dark, #174273);
    box-shadow: 0px 0px 20px 0px rgba(7, 211, 196, 0.5);
    box-sizing: border-box;
    width: 100%;
    max-width: 415px;
    position: relative;

    &__imgClose {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    &__imgWrapper {
      width: 162px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__img {
        width: inherit;
        height: inherit;
      }
    }

    &__textCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      width: 100%;

      &__firstText {
        color: var(--text-color-main, #e9e9e9);
        text-align: center;
        font-size: 18px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      &__SecondText {
        color: var(--text-color-60, rgba(220, 220, 220, 0.6));
        text-align: center;
        font-size: 13px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
