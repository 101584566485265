
.ScenarioResult{
  background: #174273;
  min-height: calc(100vh - 84.8px);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  width: 100%;
}