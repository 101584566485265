.Story {
  background: #174273;
  &-container {
    display: flex;
    flex-direction: column;
    &-banner {
      padding: 60px 0;
      margin-inline: auto;
      max-width: calc(100vw - (240px * 2));
      display: flex;
      justify-content: space-between;
      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 40px;
        &__text {
          max-width: 500px;
          font-size: 24px;
          line-height: 36px;
          color: #e0e0e0;
          > span {
            color: #07d3c4;
            font-size: 32px;
            line-height: 48px;
          }
        }
        &__line {
          background: #07d3c4;
          border-radius: 6px;
          width: 100px;
          margin: 16px 0px;
          height: 2px;
        }
      }

      &__img {
        height: 252px;
      }
    }

    &-links {
      display: flex;
      justify-content: center;
      margin-bottom: 44px;

      > div {
        background: #20528b;
        border: 1px solid rgba(7, 211, 196, 0.4);
        border-radius: 8px;
        padding: 4px;
        height: 42px;
        display: flex;
      }

      &__item {
        color: rgba(254, 254, 254, 0.6);
        font-size: 14px;
        line-height: 22px;
        padding: 10px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.isActive {
  background: #07d3c4;
  color: #254365;
  border-radius: 6px;
}

@media screen and (max-width: 1280px) {
  .Story-container-banner {
    padding: 60px 0;
    max-width: calc(100vw - (100px * 2));
    &__img {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .Story-container-banner {
    padding: 60px 20px;
  }
}

@media screen and (max-width: 768px) {
  .Story-container-banner__content {
    margin-right: 0;
  }

  .Story-container-banner__img img {
    margin-inline: auto;
  }

  .Story-container-banner {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .Story {
    &-container {
      &-banner {
        max-width: calc(100vw - (20px * 2));
        padding: 11px 20px 30px;
      }

      &-links {
        &__item {
          flex: 1;
          text-align: center;
          padding: 8px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > div {
          max-width: calc(100vw - (20px * 2));
          margin-inline: auto;
          width: 100%;
        }
      }
    }
  }
}
