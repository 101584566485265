.BookModal {
  &.content {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: unset;
    margin-bottom: 20px;
    z-index: 9;
    .BookModal__Content {
      border: unset;
      box-shadow: unset;
    }
  }
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.0902, 0.2588, 0.451, 0.3);
  &__Content {
    display: flex;
    padding: 50px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--accent-brand, #07d3c4);
    background: var(--accent-dark, #174273);
    box-shadow: 0px 0px 20px 0px rgba(7, 211, 196, 0.5);
    width: 100%;
    max-width: 415px;
    position: relative;
    box-sizing: border-box;
    // max-height: 80vh;
    // overflow: auto;
    &__Description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      gap: 8px;
      &__titleWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        &__num {
          color: var(--text-color-main, #fefefe);
          text-align: center;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
        }
        &__title {
          color: var(--text-color-main, #fefefe);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
      &__text {
        color: var(--text-color-60, rgba(254, 254, 254, 0.6));
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      &__profile {
        color: var(--accent-brand, #07d3c4);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
    &__imgWrapper {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      right: 0px;
      &__img {
        width: 100%;
        height: 100%;
      }
    }
    &__sliderCont {
      width: 100%;
      max-width: 367px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      &__text {
        color: var(--text-color-60, rgba(254, 254, 254, 0.6));
        text-align: center;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      &__rateCont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        max-width: 367px;
        width: 100%;
        &__circles {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 2px 5px;
          gap: 2px;
          isolation: isolate;
          max-width: 367px;
          width: 100%;
          height: 11px;
          background: #20528b;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
          border-radius: 20px;
          position: relative;
          &__circle {
            width: 7px;
            height: 7px;
            background: rgba(254, 254, 254, 0.4);
            border-radius: 3.5px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
          }
          &__inp {
            margin: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            gap: 2px;
            isolation: isolate;
            width: 100%;
            height: 11px;
            background: transparent;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            position: absolute;
            accent-color: #20528b;
            outline: none;
            border: none;
            appearance: none;
          }
        }
        &__num-cont {
          max-width: 360px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 2px 5px;
          gap: 2px;
          isolation: isolate;
          &__num {
            width: 8px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #07d3c4;
          }
        }
      }
    }
    &__btnCont {
      display: flex;
      width: 100%;
      max-width: 367px;
      align-items: flex-start;
      gap: 24px;
      &__cancelBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid var(--accent-brand, #07d3c4);
        height: 40px;
        width: 100%;
        max-width: 171.5px;
        background-color: transparent;
        cursor: pointer;
        &__text {
          color: var(--accent-brand, #07d3c4);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &__BookBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex: 1 0 0;
        height: 40px;
        border-radius: 8px;
        background: var(--accent-brand, #07d3c4);
        width: 100%;
        max-width: 171.5px;
        border: none;
        cursor: pointer;
        &__text {
          color: var(--accent-dark, #174273);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
  #BookSlider::-webkit-slider-thumb {
    appearance: none;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    background: #20528b;
    border: 2px solid #07d3c4;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    flex: none;
    order: 9;
    flex-grow: 0;
    z-index: 9;
    border-radius: 50%;
    cursor: pointer;
  }
  .BookSlider::-webkit-slider-thumb {
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
  #BookSlider.center-2::-webkit-slider-thumb {
    transform: translate(-5px);
  }
  #BookSlider.center-1::-webkit-slider-thumb {
    transform: translate(-2.5px);
  }
  #BookSlider.end-2::-webkit-slider-thumb {
    transform: translate(4px);
  }
  #BookSlider.end-1::-webkit-slider-thumb {
    transform: translate(5px);
  }
  .slider_none::-webkit-slider-thumb {
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
  .none {
    display: none;
  }
}
@media screen and (max-width: 391px) {
  .BookModal {
    width: 100%;
    box-sizing: border-box;
    &__Content {
      width: 100%;
      max-width: 360px;
      padding: 40px 16px 16px 16px;
      gap: 16px;

    }
  }
}
@media screen and (max-width: 360px) {
  .BookModal {
    width: 100%;
    box-sizing: border-box;
    &__Content {
      width: 100%;
      max-width: 320px;
      padding: 40px 16px 16px 16px;
      gap: 16px;
      &__imgWrapper {
        width: 55px;
      }
      &__Description {
        order: 1;
        &__titleWrapper {
          width: 100%;
          &__title {
            color: var(--text-color-main, #fefefe);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }
        &__text {
          color: var(--text-color-60, rgba(254, 254, 254, 0.6));
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &__sliderCont{
        order:3;
        width: 100%;
        max-width: 288px;
      }
      &__btnCont{
        order: 2;
        width: 100%;
        max-width: 288px;
        gap: 24px;
      }
    }
  }
}
