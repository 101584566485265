.emptyCustomSessionCont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &__emptArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 100%;
    max-width: 500px;
    &__input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 9px 16px;
      gap: 16px;
      width: 100%;
      max-width: 360px;
      background: #20528b;
      border-radius: 8px;
      outline: none;
      border: none;
      color: rgba(254, 254, 254, 0.6);
      box-sizing: border-box;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(254, 254, 254, 0.6);
  }
    &__text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #fefefe;
    }
    &__btn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 31px;
      gap: 6px;
      width: 100%;
      max-width: 360px;
      height: 40px;
      border: 1px solid #07d3c4;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #07d3c4;
      }
    }
  }
}