.SkillModal {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.0902, 0.2588, 0.451, 0.3);
  overflow: auto;
  &__content {
    display: flex;
    width: 100%;
    max-width: 320px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--accent-brand, #07d3c4);
    background: var(--accent-dark, #174273);
    box-shadow: 0px 0px 20px 0px rgba(7, 211, 196, 0.5);
    box-sizing: border-box;
    &__title {
      color: var(--text-color-main, #fefefe);
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    &__description {
      color: var(--text-color-60, rgba(254, 254, 254, 0.6));
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &__ratesCont {
      display: flex;
      width: 320px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;
      max-width: 288px;
      &__ratingSystem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 288px;
        gap: 3px;
        .first {
          border-radius: 8px 0px 0px 8px;
        }
        .end {
          border-radius: 0px 8px 8px 0px;
        }
        &__rate {
          display: flex;
          height: 44px;
          width: 100%;
          max-width: 56.8px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          background: var(--accent-main, #20528b);
          box-sizing: border-box;
          border: transparent;
          cursor: pointer;
          &__text {
            color: var(--text-color-main, #fefefe);
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
      &__info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__textLeft {
          color: var(--text-color-40, rgba(254, 254, 254, 0.4));
          text-align: left;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          width: 69px;
        }
        &__textRight {
          color: var(--text-color-40, rgba(254, 254, 254, 0.4));
          text-align: right;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          width: 69px;
        }
      }
    }
    &__btnsWrapper {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      &__cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid var(--accent-brand, #07d3c4);
        height: 40px;
        background-color: transparent;
        cursor: pointer;
        &__text {
          color: var(--accent-brand, #07d3c4);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &__save {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex: 1 0 0;
        border-radius: 8px;
        background: var(--accent-brand, #07d3c4);
        border: none;
        height: 40px;
        cursor: pointer;
        &__text {
          color: var(--accent-dark, #174273);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
  .greenBorderSkill{
    border: 1px solid #07d3c4;
  }
}
