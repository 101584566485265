.SignIn {
  // background: #102c4c;
  background: var(--accent-dark, #174273);
  min-height: calc(100vh - 84px);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  color: #fefefe;
  justify-content: center;

  &-cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 380px;
    padding: 0 10px;
    width: 100%;

    &__signup {
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fefefe;
        margin-left: 8px;
        text-decoration: underline;
      }
      cursor: pointer;
      width: 100%;
      margin-top: 24px;
      padding: 8px 0px;
      text-align: center;
    }

    &__title {
      color: #fefefe;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }

    &__input {
      // width: 360px;
      width: 100%;
      margin-top: 24px;

      .input {
        // width: 328px;
        margin-top: 16px;

        &:nth-child(1) {
          margin-top: 0;
        }

        > p {
          font-size: 13px;
          line-height: 20px;
          color: rgba(254, 254, 254, 0.6);
          margin-bottom: 8px;
        }

        > input {
          width: 100%;
          // background: #254365;
          background: var(--accent-main, #20528b);
          border-radius: 8px;
          font-size: 14px;
          line-height: 22px;
          padding: 9px 16px;
          border: none;
          font-size: 14px;
          line-height: 22px;
          color: #fefefe;
        }
      }
    }

    &__link {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;

      > a {
        color: rgba(254, 254, 254, 0.6);
        text-decoration-line: underline;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }

    > button {
      background: #07d3c4;
      border-radius: 8px;
      font-weight: 500;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      border: none;
      margin-top: 24px;
      padding: 8px 0px;
    }
  }

  > img {
    position: absolute;
    right: 0;
  }

  .Signin-errorCont {
    width: 100%;
    max-width: 290px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    &__text {
      color: var(--fixed-color-red, #ff002f);
      font-size: 13px;
      line-height: 20px;
      display: flex;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 360px) {
  .SignIn {
    justify-content: start;

    &-cont {
      // width: 320px;
      margin-top: 16px;

      // &__input {
      //     // width: 320px;
      //     .input {
      //         // width: 320px;
      //         input {
      //             // width: 288px;
      //         }
      //     }
      // }
      > button {
        position: absolute;
        // width: 320px;
        width: calc(100% - 20px);
        bottom: 20px;
      }
    }

    > img {
      display: none;
    }
  }
}
