.scenario2-Questions {
  &_intro {
    position: absolute;
    opacity: 0.8500000238418579;
    background: var(--accent-dark, #174273);
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 10;
    left: 0;
  }

  &__cont {
    min-height: calc(100vh - (92.8px + 92px));
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  background-color: #174273;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: Poppins;
      font-weight: 600;
      line-height: 24px;
      color: #ffbe00;
      margin-top: 40px;
    }

    &__hint {
      text-align: center;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.5);
      font-family: Poppins;
      font-weight: 300;
    }

    &__QuetionsCont {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      padding-left: 32px;
      padding-right: 32px;
      box-sizing: border-box;
      &__items-item {
        position: relative;
      }

      &__intro {
        img {
          margin-top: 10px;
        }
        p {
          color: var(--fixed-color-yellow, #ffbe00);
          text-align: center;
          /* Body/B2 */
          font-size: 16px;
          font-family: Poppins;
          font-weight: 500;
          line-height: 24px;
          margin-left: 7px;
        }

        position: absolute;
        transform: translate(-8px, -55px);
        z-index: 100;
        left: 50%;
        width: max-content;
        display: flex;
        align-items: flex-start;
      }

      &__question {
        &.isSelected,
        &:hover {
          z-index: 100;
          border-color: #07d3c4;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--accent-main, #20528b);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        cursor: grab;
        position: relative;
        border: 1px solid transparent;
        padding: 5px 12px;
        box-sizing: border-box;

        &__text {
          color: #fefefe;
          text-align: center;
          font-size: 14px;
          font-family: Poppins;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  &__imgWrapper {
    height: 182px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__inner {
      z-index: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;

      &__imgWrapper {
        display: flex;
        flex-direction: column;

        &__bigCone {
          position: absolute;
          top: 24px;
          pointer-events: none;
          width: 100%;
          max-width: 248px;
          z-index: 4;
        }
      }

      &__ratedContainer {
        position: absolute;
        top: 0px;
        height: 140px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;

        &__Rate {
          width: 240px;
          height: inherit;
          position: relative;

          &__coneText {
            cursor: pointer;
            &:nth-child(7n) {
              transform: rotate(-65.564deg);
              left: calc(50% + 52px);
              top: 23px;
              max-width: 50px;
            }

            &:nth-child(7n + 1) {
              transform: rotate(-108.488deg);
              left: calc(50% - 117px);
              top: 23px;
              max-width: 50px;
            }

            &:nth-child(7n + 2) {
              transform: rotate(-100.857deg);
              left: calc(50% - 132px);
              top: 22px;
              width: 129px;
              z-index: 1;
            }

            &:nth-child(7n + 3) {
              transform: rotate(-93.765deg);
              left: calc(50% - 118px);
              top: 9px;
              width: 152px;
              z-index: 2;
            }

            &:nth-child(7n + 4) {
              transform: rotate(-88.403deg);
              left: calc(50% - 102px);
              top: -10px;
              width: 185px;
              z-index: 3;
            }

            &:nth-child(7n + 5) {
              transform: rotate(-82.08deg);
              left: calc(50% - 56px);
              top: 9px;
              width: 152px;
              z-index: 2;
            }

            &:nth-child(7n + 6) {
              transform: rotate(-73.36deg);
              left: calc(50% - 20px);
              top: 22px;
              width: 129px;
              z-index: 1;
            }

            overflow: hidden;
            text-overflow: clip;
            display: inline-block;
            white-space: nowrap;
            padding: 5px 12px;
            border-radius: 8px;
            border: 1px solid #07d3c4;
            background: #20528b;
            color: #fefefe;
            text-align: center;
            font-size: 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            position: absolute;
            direction: rtl;
            text-align: right;
          }
        }
      }
    }

    &__ratesCont {
      width: 100%;
      height: inherit;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;

      &__rate {
        &__tooltip {
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            transform: translate(-50%, 100%);
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #ffffff;
          }

          position: absolute;
          color: var(--accent-main, #20528b);
          font-size: 16px;
          font-family: Poppins;
          font-weight: 500;
          line-height: 24px;
          padding: 12px;
          border-radius: 8px;
          background: var(--text-color-main, #fefefe);
          box-sizing: border-box;
          transform: translate(0, -76px);
          z-index: 100;
          max-width: 320px;
        }

        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 40px;
        font-family: Staatliches;
        line-height: 60px;
        position: relative;
        z-index: 10;
        color: var(--text-color-main, #fefefe);
        width: 100%;
        max-width: 248px;

        > span {
          width: 60px;
          height: 60px;
          border-radius: 100px;
          background: rgba(23, 66, 115, 0.6);
          cursor: pointer;
          text-align: center;
          font-size: 40px;
          font-family: Staatliches;
          line-height: 60px;
        }
      }
    }
  }

  .pickedPlace {
    border-radius: 8px;
    background: rgba(37, 67, 101, 0.01);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 0px;
  }

  .hidden {
    visibility: hidden;
  }

  .tagWithouText {
    border-radius: 8px;
    background: rgba(37, 67, 101, 0.01);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 11px;
    box-sizing: border-box;
    border: 1px solid transparent;
    cursor: "no-drop";
  }
  .unvisibleText {
    color: transparent;
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 22px;
    visibility: hidden;
  }
  .unVisibleIntro {
    display: none;
  }
  .unVisibleImages {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .scenario2-Questions {
    &__cont {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 24px;
      box-sizing: border-box;
      &__imgs {
        width: 100%;
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        margin-top: 77px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        overflow: auto;
        opacity: 0.1;
      }
    }
    &__items {
      gap: 16px;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      &__intro {
        color: var(--text-color-main, #fefefe);
        text-align: center;
        font-family: Staatliches;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }
      &__title {
        margin-top: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
      }
      &__QuetionsCont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding: 0px;
        // &__items-item {
        //   flex: 1 0 calc(50% - 8px);
        //   word-wrap: break-word;
        // }
        // &__question {
        //   border-radius: 8px;
        //   background: var(--accent-main, #20528b);
        //   box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        //   box-sizing: border-box;
        //   flex-basis: 100%;
        // }
        &__itemWrapper {
          display: flex;
          padding: 5px 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--accent-main, #20528b);
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
          cursor: pointer;
          &__item {
            color: var(--text-color-main, #fefefe);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .scenario2-Questions {
    &__items {
      // &__intro {
      //   color: var(--text-color-main, #fefefe);
      //   text-align: center;
      //   font-family: Staatliches;
      //   font-size: 24px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: 36px;
      // }
      &__QuetionsCont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 8px;
        width: 100%;
        padding: 0px;
        flex: 1 0 calc(50% - 8px);
        word-wrap: break-word;
        width: 100%;
        max-width: 320px;
        &__itemWrapper {
          display: flex;
          padding: 5px 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--accent-main, #20528b);
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
          box-sizing: border-box;
          min-width: 50px;
          max-width: 150px;
          cursor: pointer;
          &__item {
            color: var(--text-color-main, #fefefe);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            white-space: normal;
            word-break: break-word;
          }
        }
      }
    }
  }
}
.greenBorder {
  border-radius: 8px;
  border: 1px solid var(--accent-brand, #07d3c4);

.greenText{
  color: var(--accent-brand, #07D3C4);
}}
