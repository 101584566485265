.Success {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.0902, 0.2588, 0.451, 0.3);
  overflow: auto;
  padding: 10px;
  &__content {
    display: flex;
    width: 100%;
    max-width: 460px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--accent-brand, #07d3c4);
    background: var(--accent-dark, #174273);
    box-shadow: 0px 0px 20px 0px rgba(7, 211, 196, 0.5);
    &__heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      &__imgWrapper {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__img {
          width: 100%;
          height: 100%;
        }
      }
      &__title {
        color: var(--text-color-main, #fefefe);
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
      &__text {
        color: var(--text-color-60, rgba(254, 254, 254, 0.6));
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
    &__btn {
      display: flex;
      height: 44px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      border-radius: 8px;
      background: var(--accent-brand, #07d3c4);
      width: 100%;
      border: none;
      cursor: pointer;
      &__ok {
        color: var(--accent-dark, #174273);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .Success {
    padding: 10px;
    &__content {
      width: 100%;
      max-width: 320px;
      padding: 16px;
      gap: 12px;
      &__heading {
        &__imgWrapper {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
