.CancelModal-cont {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.0902, 0.2588, 0.451, 0.3);
  overflow: auto;
  &__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    position: relative;
    width: 100%;
    max-width: 360px;
    background: #174273;
    border: 1px solid #07d3c4;
    box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.5);
    border-radius: 8px;
    &__imgWrapper {
      width: auto;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__img {
        max-width: auto;
        height: 100%;
      }
    }
    &__textCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 12px;
      width: 100%;
      max-width: 312px;
      &__title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #fefefe;
      }
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: rgba(254, 254, 254, 0.6);
      }
    }
    &__btnContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;
      max-width: 312px;
      &__btn1 {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 100%;
        max-width: 144px;
        height: 40px;
        border: 1px solid #07d3c4;
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #07d3c4;
        }
      }
      &__btn2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 100%;
        max-width: 144px;
        height: 40px;
        background: #07d3c4;
        border-radius: 8px;
        box-sizing: border-box;
        border: none;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #174273;
        }
      }
    }
  }
}
@media screen and (max-width: 360px){
  .CancelModal-cont__content{
    width: 100%;
    max-width: 320px;
  }
}
