.CareerCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-top: 8px;
  width: 100%;

  &__chatbot {

    &__input {
      background-color: #20528B;
      border-radius: 8px;
      padding: 9px 16px;
      display: flex;
      margin-top: 50px;
      justify-content: space-between;

      input {
        background-color: transparent;
        border: unset;
        width: 100%;
        color: #F9F9F9;
        font-size: 16px;

        &:focus-visible {
          outline: 0px;
        }

        &::placeholder {
          color: #F9F9F9;
        }
      }

      button {
        background-color: transparent;
        border: unset;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #FEFEFE;
      display: flex;
      align-items: center;

      img {
        margin-left: 25px;
      }
    }

    ul {
      margin-left: 0;
      margin-top: 21px;
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 8px;

        .badge {
          background-color: transparent;
          border: 1px solid var(--accent-brand, #07d3c4);
          border-radius: 6px;
          padding: 6px 13px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(254, 254, 254, 0.8);
          cursor: pointer;

          &.active {
            box-shadow: 0px 4px 8px -4px #07D3C4;
          }
        }
      }
    }

    &__des {
      background-color: #20528B;
      border-radius: 8px;
      padding: 16px 24px 24px;
      color: rgba(254, 254, 254, 0.8);
      margin: 19px 0;

      &__message {
        position: absolute;
        top: -45px;
        background-color: #dddddd;
        color: #174273;
        border-radius: 5px;
        padding: 7px;
        animation: fadeIn 1s ease-out;
        /* Apply fade-in animation */
      }

      span {
        display: flex;
        color: #F9F9F9;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        justify-content: flex-end;
      }

      p {
        font-size: 16px;
        text-align: justify;
        font-weight: 500;
        line-height: 24px;
      }

      &__btns {
        margin-top: 10px;
        display: flex;
        align-items: center;
        position: relative;

        button {
          &.btn-disabled{
            opacity: 0.7;
            cursor: auto;
          }
          padding-right: 15px;
          cursor: pointer;
          border: unset;
          background-color: transparent;
          font-weight: 500;
          color: #F9F9F9;
          line-height: 24px;
          font-size: 13px;
        }
      }
    }
  }

  .infoCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    &__iconWrapper {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__icon {
        width: 14px;
        height: 14px;
      }
    }

    &__textCont {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__Profecy {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #fefefe;
      }

      &__mentor {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fefefe;

        &__key {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(254, 254, 254, 0.4);
          text-align: right;
        }
      }
    }
  }

  &__decription {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    white-space: pre-line;
    color: rgba(254, 254, 254, 0.6);
    padding-right: 20px;

    @media screen and (max-width: 600px) {
      padding-right: 0px;
    }

    &__content {
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 968px) {
        display: block;
      }

      &Img {
        height: 552px;
        border-radius: 20px;
        object-fit: cover;
        width: 35%;

        @media screen and (max-width: 968px) {
          margin: 20px auto 0px;
          width: 100%;
        }
      }
    }
  }

  &__btnWrapper {
    width: 100%;

    &__btn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 100%;
      max-width: 72px;
      height: 40px;
      border: 1px solid #07d3c4;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;

      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #07d3c4;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .CareerCont {
    margin-top: 0px;
    height: 100%;
    // height: calc(100vh - 56.8px);
    justify-content: space-between;

    &__btnWrapper {
      // height: calc(100vh - (32px + 150px));
      // height: calc(100vh - 56.8px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      max-width: 1024px;

      &__btn {
        width: 100%;
        margin-bottom: 20px;
        width: 100%;
        max-width: 1024px;
      }
    }
  }

  .hide {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .CareerCont {
    margin-top: 0px;
    height: 100%;
    height: calc(100vh - 56.8px);
    justify-content: space-between;

    &__btnWrapper {
      height: calc(100vh - (32px + 56.8px));
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &__btn {
        width: 100%;
        max-width: 320px;
        margin-bottom: 20px;
      }
    }
  }

  .hide {
    display: none;
  }
}

.tooltip {
  &-container {
    position: relative;
    display: inline-block;

    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }

  &-text {
    visibility: hidden;
    width: 270px;
    
    text-align: center;
    bottom: 150%;
    top: unset;
    /* Position the tooltip above the button */
    left: 50%;
    transform: translateX(calc(-50% - 50px));
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}