.Signup {
    background: #174273;
    min-height: calc(100vh - 84px);
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #FEFEFE;

    > h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        max-width: 498px;
        padding: 0 20px;
        text-align: center;
        margin-top: 49px;
        margin-bottom: 49px;

        span {
            color: inherit;
            font: inherit;
        }
    }

    &-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 32px;
        margin-bottom: 68px;

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 24px;
            flex: 1;

            > div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 16px;
            }

            img {
                max-width: 100%;
                height: auto;
            }

            a {
                width: fit-content;
                text-align: center;
                border: 1px solid rgba(254, 254, 254, 0.4);
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #FEFEFE;
                padding: 8px 24px;
                background: transparent;
            }
        }
    }

    >p {
        margin-bottom: 50px;
        color: rgba(254, 254, 254, 0.6);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        >span {
            color: #07D3C4;
            font-weight: 600;
            margin-left: 8px;
        }
    }
}

@media screen and (max-width: 768px) {
    .Signup {
        min-height: calc(100vh - 65px);

        &-container {
            max-width: calc(100vw - (20px * 2));
            width: 100%;
            margin-inline: auto;
        }
    }
}

@media screen and (max-width: 480px) {
    .Signup {
        &-container {
            flex-direction: column;
            column-gap: 0;
            row-gap: 24px;

            &-item {
                width: 100%;
                row-gap: 0;

                a {
                    width: 100%;
                    text-align: center;
                    // max-width: calc(100% - (25px * 2));
                    border-radius: 0px 0px 8px 8px;
                }

                > div {
                    border-radius: 8px 8px 0px 0px;

                    img {
                        max-width: 164px;
                    }
                }
            }
        }

         > h3 {
             font-size: 16px;
             line-height: 24px;
             margin: 16px 0;

             span {
                 color: #07D3C4;
             }
         }

        > p {
            row-gap: 8px;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;

            span {
                text-decoration-line: underline;
            }
        }
    }
}