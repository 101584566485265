.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  .input-wrapper {
    position: relative;
  }

  .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 14px;
    cursor: pointer;
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
  }

  .input__error {
    margin-top: 0.25rem; /* Space above error message */
    font-size: 0.875rem; /* Smaller font for error text */
    color: #f56565; /* Red color for errors */
  }

  label {
    font-size: 1rem; /* Adjust font size as needed */
    color: #a0aec0; /* Light gray color for labels */
    margin-bottom: 0.2rem; /* Space between label and input */
    font-weight: 500; /* Semi-bold for a clear label appearance */
    padding-left: 0;
  }
  input {
    padding: 0.75rem 1rem; /* Padding for input field */
    font-size: 1rem; /* Font size of the input */
    border: none; /* Remove default border */
    border-radius: 0.2rem; /* Rounded corners */
    background-color: #cbe0ff; /* Background color of the input field */
    outline: none; /* Remove outline */
    transition: background-color 0.2s, box-shadow 0.2s, color 0.2s; /* Smooth transition for hover, focus states, and color */
    width: 100%;

    &:focus {
      background-color: #ffffff; /* White background on focus */
      color: #2d3e50; /* Darker text color for contrast on white background */
      box-shadow: 0 0 0 2px rgba(45, 62, 80, 0.2); /* Soft focus effect */
    }
  }

  .select-field {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #333;
    appearance: none; // Removes default browser styles
    background-color: #cbe0ff;
    background-size: 12px; // Size of the custom arrow

    &:focus {
      outline: none;
      border-color: #00d8cc;
      box-shadow: 0 0 0 2px rgba(0, 216, 204, 0.2);
    }

    &.select--error {
      border-color: #e74c3c; // Red color for error state
    }
  }
}
