.tagInfo-cont {
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, calc(-100% + 1px));
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ffffff; /* Adjust the color as needed */
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  background: var(--text-color-main, #fefefe);
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, calc(100% + 8px));
  z-index: 100;
  left: 50%;
  width: 320px;
  margin-top: 10px;

  &__text {
    display: flex;
    width: 100%;
    max-width: 319px;
    flex-direction: column;
    color: var(--accent-main, #20528b);
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  &__btnWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__btn {
      display: flex;
      justify-content: center;
      padding: 8px 24px;
      box-sizing: border-box;
      align-items: center;
      height: 40px;
      gap: 6px;
      border-radius: 8px;
      border: 1px solid var(--accent-main, #20528b);
      cursor: pointer;
      &__text {
        color: var(--accent-main, #20528b);
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
.tagInfo-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 50;
  background: transparent;
}