.activeSession-cont {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0px;
  gap: 16px;
  width: 100%;
  // max-width: 890px;
  flex-wrap: wrap;
  &__items {
    width: 100%;
    max-width: 286px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1px;
    &__dateCont {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 8px 16px;
      gap: 10px;
      width: 100%;
      max-width: 286px;
      background: #20528b;
      border-radius: 8px 8px 0px 0px;
      box-sizing: border-box;
      &__date {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #fefefe;
      }
      &__duration {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #fefefe;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 12px;
      width: 100%;
      height: 100%;
      max-width: 286px;
      background: #20528b;
      border-radius: 0px 0px 8px 8px;
      box-sizing: border-box;
      &__topicCont {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        width: 100%;
        &__topic {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.4);
        }
        &__subject {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #fefefe;
        }
      }
      &__mentorCont {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        &__mentor {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.4);
        }
        &__mentorsName {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #fefefe;
        }
      }
      &__btn {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 6px;
        width: 100%;
        max-width: 254px;
        height: 40px;
        border: 1px solid #07d3c4;
        border-radius: 8px;
        background-color: transparent;
        margin-top: auto;
        cursor: pointer;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #07d3c4;
        }
      }
    }
  }
}
.emptyActiveSessionCont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &__emptArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 100%;
    max-width: 315px;
    &__text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #fefefe;
    }
    &__btn {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 31px;
      gap: 6px;
      width: 100%;
      max-width: 175px;
      height: 40px;
      border: 1px solid #07d3c4;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #07d3c4;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .activeSession-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 100%;
    max-width: 1024px;
    &__items {
      width: 100%;
      max-width: 320px;
      &__dateCont {
        width: 100%;
        max-width: 320px;
      }
      &__info {
        width: 100%;
        max-width: 320px;
        &__btn {
          width: 100%;
          max-width: 288px;
        }
      }
    }
  }
  .emptyActiveSessionCont {
    height: calc(100vh - 116.8px);
    &__emptArea {
      width: 100%;
      max-width: 280px;
      gap: 24px;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .activeSession-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    max-width: 320px;
    &__items {
      width: 100%;
      max-width: 320px;
      &__dateCont {
        width: 100%;
        max-width: 320px;
      }
      &__info {
        width: 100%;
        max-width: 320px;
        &__mentorCont{
          flex-direction: column;
          align-items: flex-start;
        }
        &__btn {
          width: 100%;
          max-width: 288px;
        }
      }
    }
  }
  .emptyActiveSessionCont {
    height: calc(100vh - 116.8px);
    &__emptArea {
      width: 100%;
      max-width: 280px;
      gap: 24px;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
