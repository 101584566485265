.EditProfileCont {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  &__title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
    width: 100%;
    max-width: 424px;
    margin-bottom: 16px;
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
    max-width: 424px;
    &__items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      width: 100%;
      max-width: 200px;
      position: relative;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(254, 254, 254, 0.6);
      }
      &__inp {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 9px 16px;
        gap: 16px;
        width: 100%;
        max-width: 200px;
        background: #20528b;
        border-radius: 8px;
        outline: none;
        border: none;
        color: rgba(254, 254, 254, 0.6);
        box-sizing: border-box;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(254, 254, 254, 0.6);
        &::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.6);
        }
      }
      &__select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 9px 16px;
        gap: 16px;
        width: 100%;
        max-width: 200px;
        background: #20528b;
        border-radius: 8px;
        box-sizing: border-box;
        &__text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(254, 254, 254, 0.6);
        }
        &__imgWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          cursor: pointer;
          &__img {
            width: 12px;
            height: 6px;
          }
        }
      }
      &__ageList {
        height: 105px;
        z-index: 5;
        position: absolute;
        bottom: 0px;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        // padding: 9px 16px;
        width: 100%;
        max-width: 200px;
        background: #20528b;
        border-radius: 8px;
        box-sizing: border-box;
        overflow-y: scroll;
        &__age {
          text-align: left;
          color: var(--text-color-60, rgba(254, 254, 254, 0.6));
          padding-left: 16px;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          background-color: transparent;
          cursor: pointer;
          &:hover {
            background-color: var(--accent-dark, #102C4C);;
        }
        }
      }
    }
  }
  &__adressCont {
    width: 100%;
    max-width: 424px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    &__text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: rgba(254, 254, 254, 0.6);
    }
    &__inp {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 9px 16px;
      gap: 16px;
      width: 100%;
      max-width: 424px;
      background: #20528b;
      border-radius: 8px;
      outline: none;
      border: none;
      color: rgba(254, 254, 254, 0.6);
      box-sizing: border-box;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(254, 254, 254, 0.6);
      &::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(254, 254, 254, 0.6);
      }
    }
  }
  &__btnCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px 0px 0px;
    gap: 24px;
    width: 100%;
    max-width: 424px;
    box-sizing: border-box;
    &__btnCancel {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 100%;
      max-width: 200px;
      height: 40px;
      border: 1px solid #20528b;
      border-radius: 8px;
      cursor: pointer;
      background-color: transparent;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(254, 254, 254, 0.6);
      }
    }
    &__btnSaveChange {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 100%;
      width: 200px;
      height: 40px;
      border-color: transparent;
      background: #07d3c4;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      &__text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #174273;
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .EditProfileCont__content__items__ageList::-webkit-scrollbar {
    width: 8px;
  }
  
  .EditProfileCont__content__items__ageList::-webkit-scrollbar-track {
    // color: var(--text-color-60, rgba(254, 254, 254, 0.6));
    background-color: var(--text-color-60, rgba(254, 254, 254, 0.6));
  }
  // var(--text-color-60, rgba(254, 254, 254, 0.6)
  .EditProfileCont__content__items__ageList::-webkit-scrollbar-thumb {
    background:  #174273;;
    border-radius: 4px;
    height: 35px;
  }
}
@media screen and (max-width: 1024px) {
  .EditProfileCont__btnCont {
    height: calc(100vh - 728.8px);
    &.Student {
      height: calc(100vh - 804.8px);
    }
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .EditProfileCont {
    margin-top: 0px;
    width: 100%;
    max-width: 320px;
    height: 100%;
    padding-bottom: 20px;
    &__title {
      margin-bottom: 0px;
    }
    &__content {
      gap: 12px;
      width: 100%;
      max-width: 320px;
      &__items {
        width: 100%;
        max-width: 150px;
        &__inp {
          width: 100%;
          max-width: 150px;
        }
        &__select {
          width: 100%;
          max-width: 154px;
        }
      }
    }
    &__adressCont {
      width: 100%;
      max-width: 320px;
      &__inp {
        width: 100%;
        max-width: 320px;
      }
    }
    &__btnCont {
      height: 100%;
      width: 100%;
      max-width: 320px;
      padding: 0px;
      height: calc(100vh - (48px + 90px + 16px + (5 * 68px) + 56.8px));
      padding-top: 20px;
      padding-bottom: 0px;
      gap: 12px;
      &__btnCancel {
        width: 100%;
        max-width: 154px;
      }
      &__btnSaveChange {
        width: 100%;
        max-width: 154px;
        padding: 0px;
        
      }
    }
  }
}