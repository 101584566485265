// SignUp.scss
.signup {
  display: block;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 200px 20px;

  &-wrapper {
    display: flex;
    width: 100%;
    gap: 1rem; /* Adds space between the input fields */
  }

  &-checkbox-error {
    color: #ff002f;
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-explanation {
    color: #a0aec0;
  }

  &-link {
    color: #a0aec0;
    text-decoration: underline;
  }

  &-navigation-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem; /* Add space between the form and the navigation */
  }

  &-navigation__clear {
    padding: 0.75rem 2rem; /* Add padding for size */
    font-size: 1rem; /* Adjust font size */
    color: #00b9a5; /* Teal text color */
    background-color: transparent; /* Transparent background */
    border: 2px solid #00b9a5; /* Teal border matching the text */
    border-radius: 0.5rem; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.2s, color 0.2s; /* Smooth transition */
    width: 48%;
    &:hover {
      background-color: rgba(
        0,
        185,
        165,
        0.1
      ); /* Light teal background on hover */
    }

    &:focus {
      outline: none; /* Remove default focus outline */
      box-shadow: 0 0 0 2px rgba(0, 185, 165, 0.3); /* Soft focus effect */
    }
  }

  &-navigation__continue {
    width: 48%;
    padding: 0.75rem 2rem; /* Add padding for size */
    font-size: 1rem; /* Adjust font size */
    color: #2d3e50; /* Dark text color */
    background-color: #00d8cc; /* Teal background color */
    border: none; /* No border */
    border-radius: 0.5rem; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.2s, color 0.2s; /* Smooth transition */

    &:hover {
      background-color: #00c4b8; /* Darker teal on hover */
    }

    &:focus {
      outline: none; /* Remove default focus outline */
      box-shadow: 0 0 0 2px rgba(0, 185, 165, 0.3); /* Soft focus effect */
    }
    &:disabled {
      background-color: #a0aec0; /* Disabled state color */
      color: #ffffff; /* White text color */
      cursor: not-allowed; /* Not-allowed cursor */
    }
  }

  &-level {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem; // Adjust the margin as needed
    justify-content: start;
    text-align: left;
    cursor: pointer;

    &__title {
      font-size: 1rem; // Adjust font size for the title
      color: #a0aec0; // Light gray color for the title
      margin-bottom: 1rem; // Space between the title and the circles
    }

    &__cont {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 400px; // Adjust max width for the container
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      z-index: 9;
    }

    &__circle {
      width: 20px; // Size of the circle
      height: 20px;
      background-color: #4a5568; // Default circle color
      border-radius: 50%;
      margin-bottom: 0.5rem; // Space between the circle and the number
      transition: background-color 0.3s; // Smooth transition for active state
      cursor: pointer;
      z-index: 10;
      &--active {
        background-color: #00d8cc; // Active state color (teal)
        cursor: pointer;
      }
    }

    &__number {
      font-size: 0.875rem; // Smaller font size for the numbers
      color: #00d8cc; // Teal color for the numbers
    }
  }

  .input__error {
    margin-top: 0.5rem; // Space above the error message
    font-size: 0.875rem; // Smaller font size for the error text
    color: #ff002f; // Red color for errors
  }

  .password__error {
    font-size: 0.875rem; // Smaller font size for the error text
    color: #ff002f; // Red color for errors
    margin-bottom: 0.5rem;
  }
}
