.AiResult {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  padding-top: 60px;
  // if clients want that ai recomendation will be seen by users
  // position: relative;
  // z-index: 1;
  &__title {
    color: var(--text-color-main, #fefefe);
    text-align: center;
    width: 100%;
    max-width: 315px;
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  &__answerCont {
    display: flex;
    gap: 16px;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      width: 100%;
      max-width: 200px;
      &:nth-child(2) {
        margin-top: 50px;
      }
      &:nth-child(3) {
        margin-top: 100px;
      }
      &:nth-child(4) {
        margin-top: 50px;
      }
      &__cont {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        width: 100%;
        &__startPart {
          display: flex;
          width: 100%;
          max-width: 200px;
          padding: 16px 30px;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          position: relative;
          border-radius: 8px;
          background: var(--accent-main, #20528b);
          cursor: pointer;
          &__text {
            color: var(--text-color-main, #fefefe);
            text-align: center;
            width: 100%;
            font-size: 16px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            // word-break: break-all
          }
          &__numberWrapper {
            display: flex;
            width: 30px;
            height: 30px;
            padding: 0px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 8px 0px;
            background: var(--accent-brand-40, rgba(7, 211, 196, 0.4));
            box-sizing: border-box;
            &__number {
              color: var(--text-color-main, #fefefe);
              text-align: center;
              font-size: 16px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }
        &__endPart {
          color: var(--fixed-color-yellow, #ffbe00);
          font-size: 14px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        &__endPartGreen {
          font-size: 14px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: var(--accent-brand, #07d3c4);
        }
      }
    }
    // &__itemCont {
    //   display: inline-flex;
    //   flex-direction: column;
    //   align-items: center;
    //   gap: 6px;
    //   width: 100%;
    //   flex-basis: calc(20% - 40px);
    //   &__startPart {
    //     display: flex;
    //     width: 100%;
    //     max-width: 200px;
    //     padding: 16px 10px;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 10px;
    //     border-radius: 8px;
    //     background: var(--accent-main, #20528b);
    //     position: relative;
    //     &__text {
    //       color: var(--text-color-main, #fefefe);
    //       text-align: center;
    //       font-size: 16px;
    //       font-family: Poppins;
    //       font-style: normal;
    //       font-weight: 600;
    //       line-height: 24px;
    //       width: 100%;
    //       max-width: 105px;
    //     }
    //     &__numberWrapper {
    //       display: flex;
    //       width: 30px;
    //       height: 30px;
    //       padding: 0px 10px;
    //       justify-content: center;
    //       align-items: center;
    //       gap: 10px;
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       border-radius: 8px 0px;
    //       background: var(--accent-brand-40, rgba(7, 211, 196, 0.4));
    //       box-sizing: border-box;
    //       &__number {
    //         color: var(--text-color-main, #fefefe);
    //         text-align: center;
    //         font-size: 16px;
    //         font-family: Poppins;
    //         font-style: normal;
    //         font-weight: 600;
    //         line-height: 24px;
    //       }
    //     }
    //   }
    //   &__endPart {
    //     color: var(--fixed-color-yellow, #ffbe00);
    //     font-size: 14px;
    //     font-family: Poppins;
    //     font-style: normal;
    //     font-weight: 500;
    //     line-height: 22px;
    //   }
    // }
  }
  .darekYellow {
    color: var(--fixed-color-yellow, #ffbe00);
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  &__CongratsLeft {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &__CongratsRight {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.unvisibleCongrats {
  display: none;
}
.choosen {
  background: var(--accent-brand, #07d3c4);
}
@media screen and (max-width: 1024px) {
  .AiResult {
    width: 100%;
    gap: 16px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    &__title {
      font-size: 16px;
      width: 100%;
      max-width: 1024px;
    }
    &__answerCont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      padding: 0px;
      &__columns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        width: 100%;
        max-width: 1024px;
        &__cont {
          width: 100%;
          max-width: 1024px;
          position: relative;
          display: flex;
          padding: 16px 30px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 8px;
          background: var(--accent-main, #20528b);
          box-sizing: border-box;
          &__text {
            color: var(--text-color-main, #fefefe);
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          &__numberWrapper {
            display: flex;
            width: 30px;
            height: 30px;
            padding: 0px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 8px 0px;
            background: var(--accent-brand-40, rgba(7, 211, 196, 0.4));
            box-sizing: border-box;
            &__number {
              color: var(--text-color-main, #fefefe);
              text-align: center;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }
        &__ai {
          color: var(--text-color-60, rgba(254, 254, 254, 0.6));
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
  .choosen{
    background: var(--accent-brand, #07d3c4);
  }
}

.Recommendations-info {
  bottom: 10px;
  font-size: 8px;
  color: #fefefe;
  margin-right: 20px;
  margin-top: 20px;
  margin-left: 20px;
  z-index: 7;
  a {
    color: #fefefe;
    text-decoration: underline;
  }
}

.invisible {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.invisible:not(.invisible) {
  visibility: visible;
  opacity: 1;
}
