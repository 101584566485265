
.stars {
  display: flex;
  gap: 12px;
  // cursor: pointer;
  &__star {
    width: 12px;
    height: 12px;
    // cursor: pointer;
  }
  .hovered {
    fill: #fefefe;
  }
  .rated{
    fill: #fefefe;
  }
}
