.studentResources__dialog {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(168, 172, 176, 0.43);
  overflow: auto;
  padding: 10px;
  .profileCont__studentResources__sliderLoading{
    position: unset;
    transform: unset;
  }
  &__arrows {
    position: absolute;
    top: 50%;
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
    .arrow-prev{
      transform: rotate(180deg);
    }
  }
  &__content {
    position: relative;
    width: 100%;
    z-index: 11;
    max-width: 660px;
    border-radius: 30px;
    background: var(--accent-dark, #fff);
    padding: 30px 30px 50px;
    max-height: calc(100vh - 60px);
    overflow: auto;
    .videoplayer__videoContainer{
      height: auto;
    }
    .videoplayer__video{
      width: 80%;
      height: auto;
    }
    &__imgClose {
      background-color: transparent;
      border: 0px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      cursor: pointer;
    }
    .slick-initialized .slick-slide{
      padding: 0px 20px;
    }
    .coverImg {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 16px 16px 0 0;
      height: 470px;
      object-fit: cover;
      object-position: center;
    }

    h3 {
      font-size: 36px;
      font-weight: bold;
      color: #174273;
      padding-bottom: 10px;
    }

    img {
      max-width: 100%;
    }
    .content-des{
      
      img{
        width: 100% !important;
        height: auto !important;
        margin-top: 20px;
      }
      p,a {
        font-size: 16px;
        color: #174273;
        font-weight: 500;
      }
      a{
        text-decoration: underline;
        font-weight: 700;
      }
    }

    &__container {
      padding: 0px 24px 24px 24px;
      width: 100%;
    }
  }
}