.story-cont {
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 100%;
  max-width: 472px;
  background: #174273;
  border: 1px solid #07d3c4;
  box-shadow: 0px 0px 20px rgba(7, 211, 196, 0.5);
  border-radius: 8px;
  &__handpen-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    max-width: 424px;
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
    &__img-wrapper {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      &__img {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      width: 100%;
      max-width: 424px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #fefefe;
    }
  }
  &__info-cont {
    width: 100%;
    max-width: 424px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin-bottom: 24px;
    &__first {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      &__name-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px;
        &__wrapper {
          width: 100%;
          max-width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          &__inp {
            padding: 9px 16px;
            background: #20528b;
            border: 0px solid #20528b;
            color: #fefefe;
            border-radius: 8px;
            width: 100%;
            max-width: 200px;
            &::-webkit-input-placeholder {
              width: 100%;
              max-width: 78px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #fefefe;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
      &__email-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px;
        &__wrapper {
          width: 100%;
          max-width: 200px;
          max-width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          &__inp {
            padding: 9px 16px;
            background: #20528b;
            border: 0px solid #20528b;
            color: #fefefe;
            border-radius: 8px;
            width: 100%;
            max-width: 200px;
            &::-webkit-input-placeholder {
              width: 100%;
              max-width: 78px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #fefefe;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    &__second {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      &__role-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px;
        &__wrapper {
          width: 100%;
          max-width: 200px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &__select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 16px;
            gap: 16px;
            width: 100%;
            max-width: 200px;
            background: #20528b;
            border-radius: 8px;
            &__text {
              width: 100%;
              max-width: 56px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #fefefe;
            }
            &__iconwrapper {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      &__year-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px;
        &__wrapper {
          width: 100%;
          max-width: 200px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &__yearSelect {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 16px;
            gap: 16px;
            width: 100%;
            max-width: 200px;
            background: #20528b;
            border-radius: 8px;
            &__text {
              width: 100%;
              max-width: 56px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #fefefe;
            }
            &__abbr {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: rgba(254, 254, 254, 0.6);
            }
          }
        }
      }
    }
    &__third {
      display: flex;
      flex-direction: column;
      max-width: 424px;
      width: 100%;
      &__story {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 9px 16px;
        gap: 16px;
        height: 115px;
        background: #20528b;
        border-radius: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 1;
        border: none;
        resize: none;
        color: #fefefe;
        &::-webkit-input-placeholder {
            width: 100%;
            max-width: 78px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #fefefe;
        }
        &:focus {
            outline: none;
          }
      }
    }
  }
  &__btn-cont {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    max-width: 424px;
    width: 100%;
    &__cansel {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 70px 8px 71px;
      gap: 6px;
      max-width: 200px;
      border: 1px solid #07d3c4;
      border-radius: 8px;
      flex: none;
      order: 0;
      flex-grow: 1;
      background: transparent;
      cursor: pointer;
      .cansel-text {
        max-width: 58px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #07d3c4;
      }
    }
    &__send {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 80px 8px 79px;
      gap: 6px;
      max-width: 200px;
      background: #07d3c4;
      border-radius: 8px;
      flex: none;
      order: 1;
      flex-grow: 1;
      cursor: pointer;
      .send-text {
        width: 100%;
        max-width: 41px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #174273;
      }
    }
  }
  .text-modal {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: rgba(254, 254, 254, 0.6);
    margin-bottom: 8px;
  }
}
