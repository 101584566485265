$blue: #174273;

.Footer {
  display: flex;
  flex-direction: column;
  background: $blue;

  &-email {
    padding: 30px 0px;
    background: #07d3c466;
    width: 100%;

    &-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__content {
      color: #fefefe;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-right: 24px;
    }

    &__input {
      padding: 9px 16px;
      color: #fefefe;
      border: 1px solid #fefefe;
      width: 100%;
      max-width: 312px;
      border-radius: 6px 0px 0px 6px;
      background: transparent;
      outline: none;
      &::placeholder {
        color: #fefefe;
      }
    }

    &__button {
      background: #fefefe;
      border-radius: 0px 6px 6px 0px;
      font-size: 16px;
      line-height: 24px;
      border: none;
      color: $blue;
      font-weight: 500;
      padding: 8px 38px;
      cursor: pointer;
    }
  }

  &-container {
    max-width: calc(100vw - (2 * 240px));
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    margin-inline: auto;

    &-content {
      display: flex;
      justify-content: space-between;
      &__info {
        display: flex;
        flex-direction: column;
        &__logo {
          margin-bottom: 16px;
          img {
            height: 66px;
          }
        }
        > p {
          color: #fefefe;
          font-size: 13px;
          line-height: 20px;
          max-width: 356px;
          font-weight: 500;
        }
      }
      &__product {
        &__title {
          color: #fefefe;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        &__item {
          color: rgba(254, 254, 254, 0.6);
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &__company {
        &__title {
          color: #fefefe;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        &__item {
          a {
            color: rgba(254, 254, 254, 0.6);
          }
          color: rgba(254, 254, 254, 0.6);
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 8px;
          cursor: pointer;
        }
      }
      &__support {
        &__title {
          color: #fefefe;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        &__item {
          a {
            color: rgba(254, 254, 254, 0.6);
          }
          color: rgba(254, 254, 254, 0.6);
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 8px;
          cursor: pointer;
        }
      }
      &__followus {
        &__title {
          color: #fefefe;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        &__icons {
          display: flex;

          &__img {
            margin-right: 16px;
          }
        }
      }
    }

    &-line {
      width: 100%;
      height: 1px;
      background: rgba(254, 254, 254, 0.4);
      margin-top: 24px;
      margin-bottom: 24px;
    }
    &-copyright {
      width: 100%;
      display: flex;
      justify-content: center;
      > p {
        a {
          color: rgba(254, 254, 254, 0.6);
        }
        color: rgba(254, 254, 254, 0.6);
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }
    }
  }
  .errorCont {
    width: 100%;
    max-width: 290px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    &__text {
      color: var(--fixed-color-red, #ff002f);
      font-size: 13px;
      line-height: 20px;
      display: flex;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1919px) {
  .Footer {
    &-container {
      max-width: calc(100vw - (2 * 20px));
    }
  }
}

@media screen and (max-width: 991px) {
  .Footer-container-content {
    flex-direction: column;
    row-gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .Footer {
    &-email {
      &-inner {
        max-width: calc(100vw - (20px * 2));
        margin-inline: auto;
      }

      &__button {
        padding: 8px 16px;
      }

      &__input {
        flex: 1;
      }

      &__content {
        width: 100%;
        text-align: center;
        margin: 0 0 24px;
      }
    }

    &-container-copyright > p {
      text-align: center;
    }
    .errorCont {
      width: 100%;
      max-width: calc(100vw - (20px * 2));
    }
  }
}
