.FaqItems {
    max-width: calc(100vw - (240px * 2));
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    
    &-col {
        width: calc(50% - 16px);
        row-gap: 20px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        
        &-element {
            background: #20528B;
            border-radius: 8px;
            &__image {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__question {
                padding: 16px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                >p{
                   color: rgba(254, 254, 254, 0.6);
                   font-weight: 500;
                   font-size: 15px;
                   line-height: 20px;
                }
            }
    
            &__answere {
                margin-top: 16px;
                background: #20528B;
                color: rgba(254, 254, 254, 0.6);
                font-weight: 500;
                font-size: 15px;
                border-radius: 8px;
                line-height: 20px;
                padding: 0px 16px 16px 16px;
            }
        }
        
    }
}


@media screen and (max-width: 1919px) {
    .FaqItems {
        max-width: calc(100vw - (162px * 2));
    }
}

@media screen and (max-width: 991px) {
    .FaqItems {
        max-width: calc(100vw - (20px * 2));
    }
}

@media screen and (max-width: 768px) {
    .FaqItems {
        flex-direction: column;
        row-gap: 8px;

        &-col {
            width: 100%;
            row-gap: 8px;
        }
    }
}

@media screen and (max-width: 460px) {
    .FaqItems-col-element__question > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 206px;
    }

    .FaqItems-col-element__answere {
        margin-top: 0;
    }
}