
.pricing-plan {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  // height: 546px;
  background: #174273;
  &__content {
    // padding-left: 240px;
    // padding-right: 240px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 20px;
    gap: 10px;
    width: 100%;
    // height: 300px;
    background: #174273;
    &__text {
      text-align: center;
      width: 100%;
      max-width: 537px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      color: #fefefe;
      // margin-left: 240px;
    }
    &__image {
      max-width: 450px;
      width: 100%;
      height: 258px;
      flex: none;
      order: 3;
      flex-grow: 0;
      z-index: 3;
      // position: absolute;
      right: 240px;
    }
  }
  &__waveimg {
    height: 246px;
    background: #f9f9f9;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@media screen and (max-width: 1920px) {
  .pricing-plan {
    // height: 384px;
    &__content {
      // height: 220px;
      padding-top: 40px;
      padding-bottom: 40px;
      // &__text {
      //   // margin-left: 100px;
      // }
      &__image {
        right: 100px;
        top: 10px;
      }
    }
    &__waveimg {
      height: 164px;
    }
  }
}

@media (max-width: 1210px) {
  .pricing-plan {
    &__content {
      row-gap: 20px;
      flex-direction: column;

      &__image {
        position: static;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .pricing-plan {
    width: 100%;
    max-width: 360px;
    height: auto;
    &__content {
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      position: static;
      gap: 0px;
      width: 100%;
      padding-bottom: 16px;
      height: auto;
      &__text {
        width: 100%;
        max-width: 320px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        margin-left: 0px;
        margin-bottom: 16px;
      }
      &__image {
        width: 100%;
        max-width: 280px;
        height: auto;
        flex: none;
        order: 2;
        flex-grow: 0;
        z-index: 2;
        position: static;
      }
    }
    &__waveimg {
      width: 100%;
      max-width: 360px;
     height: auto;
     background: #f9f9f9;
    }
  }
}

